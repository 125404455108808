
export const enum CompDenomination {
  Cash = "cash",
  Point = "point",
  Stroke = "stroke",
}

export const enum CompEntry {
  Club = "club",
  Foursome = "foursome",
  Group = "group",
  Pair = "pair",
  Single = "single",
  Wheel = "wheel",
}

export const enum CompField {
  All = "all",
  Flight = "flight",
  Foursome = "foursome",
}

export const enum CompFormat {
  BestBall = "bestball",
  Chapman = "chapman",
  Scramble = "scramble",
  Shamble = "shamble",
  Total = "total",
}


export const enum CompHandicap {
  Gross = "gross",
  Net = "net",
}

export const enum CompScoresThatCount {
  All = "all",
  Birdies = "birdies",
  Deuces = "deuces",
  Eagles = "eagles",
  Pars = "pars",
  Treys = "treys",
}

export const enum CompScoring {
  Eclectic = "eclectic",
  Match = "match",
  Nassau = "nassau",
  SkinsBasic = "skins-basic",
  SkinsCanadian = "skins-canadian",
  SkinsSilk = "skins-silk",
  Stableford = "stableford",
  Stroke = "stroke",
}

export const CompStructure = {
  Bracket: "bracket",
  Event: "event",
  League: "league",
  RoundRobin: "roundrobin",
}

export const enum CompTiebreaker {
  ByHandicap = "byhandicap",
  Back9 = "back9",
  Custom = "custom",
  Front9 = "front9",
  Last1 = "last1",
  Last3 = "last3",
  Last6 = "last6",
  Last9 = "last9",
  None = "none",
  Total = "total",
}

export const enum CompType {
  Eclectic = "eclectic",
  Cash = "cash",
}

export default interface Competition {
  denomination?: CompDenomination;
  field?: CompField;
  entry?: CompEntry;
  format?: CompFormat;
  handicap?: CompHandicap;
  name?: string;
  scoresThatCount?: CompScoresThatCount;
  scoring?: CompScoring;
  structure?: string;
  tiebreaker?: CompTiebreaker[];
  tiebreakerCustom?: string;
  type: CompType;  // Deprecated
}