import { 
  ReactElement, 
  useEffect, 
  useState 
} from "react";

import { doc } from "firebase/firestore";
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useParams } from "react-router-dom";

import EditIcon from '@mui/icons-material/Edit';
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { useApp } from "../AppProvider";
import { useAuth } from "../AuthProvider";
import Hourglass from "../components/Hourglass";
import LeagueActionBar from "../components/LeagueActionBar";
import LeagueEditor from "../components/LeagueEditor";
import LeagueHeader from "../components/LeagueHeader";
import LeagueMembers from "../components/LeagueMembers";
import LeagueResults from "../components/LeagueResults";
import SeasonStandingCsvUpload from "../components/SeasonStandingCsvUpload";
import { useData } from "../DataProvider"; 
import { 
  dividerStyles, 
  sectionStyles, 
  subtitleStyles
 } from "../styles/styles";
import League from "../types/League";

interface LeaguePageProps {
  isEditMode?: boolean;
}

export default function LeaguePage(props: LeaguePageProps): ReactElement {
  const { setActions, setPageName } = useApp();
  const { isLeagueAdmin, isOrgAdmin } = useData();
  const { isGod } = useAuth();
  const { leaguesCol } = useData();
  const { leagueId } = useParams();

  const [isEditMode, setIsEditMode] = useState(props.isEditMode ? props.isEditMode : false);

  const [league, isLeagueLoading, leagueLoadingError] = useDocumentData<League>(doc(leaguesCol, leagueId));

  useEffect(() => {
    if (!isLeagueLoading && (leagueLoadingError || !league)) {
      console.log("League not found");
    } 
    if (league) {
      setPageName(league.name);
      if (isLeagueAdmin(league.itemId) || isOrgAdmin(league.orgId)) {
        setActions([{ 
          name: "Edit", 
          handleClick: handleEditClick, 
          icon: EditIcon }])
      }
    }
  }, [league, isLeagueLoading, leagueLoadingError, isLeagueAdmin, isOrgAdmin, setActions, setPageName]);

  const handleEditClick = () => {
    setIsEditMode(true);
  }

  const LeagueDetails = (): ReactElement => {
    if (league) {
      return (
        <Grid container width="100%" spacing={2} sx={sectionStyles}>
          <Grid item container xs={12} sm={12} > 
            <Grid item xs={12}>
              <LeagueHeader leagueId={league.itemId} />
            </Grid>
            <Grid item xs={12}>
              <LeagueActionBar leagueId={league.itemId}/>
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} spacing={2}>
            <Grid item xs={12}>
              <Divider sx={{...dividerStyles, display: {sm: 'none'}}}/>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={subtitleStyles}>  
                Results
              </Typography>
            </Grid>
            <LeagueResults leagueId={league.itemId} orgId={league.orgId}/>
            {isGod && <SeasonStandingCsvUpload />}
            <Grid item xs={12}>
              <Typography sx={subtitleStyles}>  
                Members
              </Typography>
              <LeagueMembers league={league} isEditable={true}/>
            </Grid>

          </Grid>
        </Grid>
      );
    } else {
      return (<></>);
    }
  };

  if (league) {
    if (isOrgAdmin(league.orgId) || isLeagueAdmin(league.itemId)) {
      return (
        <Box sx={sectionStyles}>
          {isEditMode ? 
            <LeagueEditor league={league} isNewLeague={false} /> :
            <LeagueDetails />}
        </Box>);
    } else {
      return (<LeagueDetails />);
    }

  } else {
    return (<Hourglass />);
  }
};
// && IsEventSignupOpen(event.signupOpensAt, event.signupClosesAt) && 
// {event.isGolf && IsEventUpcoming(event.datetime) && IsEventSignupClosed(event.signupClosesAt) &&