import { ReactElement } from "react";

import { orderBy, query } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { useAuth } from "../AuthProvider";
import { useData } from "../DataProvider";
import { 
  sectionStyles, 
  titleStyles 
} from "../styles/styles";
import Course from "../types/Course";

import { CourseRow } from "./CourseRow";

export const CourseDirectory = (): ReactElement => {
  const { isRevealingGodsSecrets } = useAuth();
  const { coursesCol } = useData();

  const q = query(coursesCol, orderBy("name"));
  
  const [courses, isLoading, isError] = useCollectionData<Course>(q);

  if (isLoading) {
    return <Box width='100vw' />;
  } else if (isError) {
    return <Box width='100vw' />;
  } else if (courses) {
    return (
      <Box sx={sectionStyles}>
        <Grid container sx={{ width: "100vw" }}>
          <Grid item xs={12}>
            <Typography align="left" gutterBottom sx={titleStyles}>
              Golf Courses
            </Typography>
          </Grid> 
          <Grid item xs={12}>
            { courses.flatMap((course: Course) => {
              if (isRevealingGodsSecrets || !course?.isTest) {
                return (<CourseRow key={course.itemId} course={course} />);
              } else {
                return null;
              }
            })}
          </Grid>  
        </Grid>
      </Box>
    );
  } else {
    return <Container />;
  }
}