import { 
  ReactElement, 
  useEffect, 
  useState 
} from "react";

import { doc, DocumentReference } from "firebase/firestore";
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { NavLink } from "react-router-dom";


import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useAuth } from "../AuthProvider";
import { useData } from "../DataProvider";
import { firestore } from "../firebase";
import Event from '../types/Event';
import Registration from '../types/Registration';
import { DisplayTime, IsEventOver } from "../utils/utils";

export default function EventRow(event:Event): ReactElement {
  const { user } = useAuth();
  const { courses } = useData();

  const [isAttending, setIsAttending] = useState(false);

  const [registration, isRegistrationLoading, isRegistrationLoadingError] = 
    useDocumentData<Registration>(doc(firestore, "event/" + event.itemId + "/reg/" + user?.uid) as DocumentReference<Registration>);

  useEffect(() => {
    if (registration) {
      setIsAttending(registration.isRegistered);
    } else {
      if (isRegistrationLoadingError) {
        console.log("Error loading registration");
      } else if (isRegistrationLoading) {
        console.log("Loading registration");
      }
    }
  }, [registration, isRegistrationLoading, isRegistrationLoadingError]);

  const StyledTime = (): ReactElement => {
    return (
      <Typography variant="caption" color="primary.main" sx={{py: 0, my: 0, lineHeight: 1 }}>
        {DisplayTime(event.datetime, "America/Los_Angeles")}
      </Typography>
    );
  }
  const StyledDate = (): ReactElement => {
    let monthText = "";
    let dayText = "";

    const numEventDays = event.datetimes ? event.datetimes.length : 1;

    if (event.datetimes && numEventDays > 1) {
      const eventStartDate = event.datetimes[0].toDate();
      const eventEndDate = event.datetimes[numEventDays-1].toDate();

      monthText = eventStartDate.toLocaleString("default", { month: "short" });
      if (eventStartDate.getMonth() !== eventEndDate.getMonth()) {
        monthText += " - " + eventEndDate.toLocaleString("default", { month: "short" });
      }
      dayText = eventStartDate.getDate().toString() + " - " + eventEndDate.getDate().toString();
    } else {
      const eventDate = event.datetime.toDate();
      monthText = 
        eventDate.toLocaleString("default", { month: "short" })  + ", " + 
        eventDate.toLocaleDateString("default", { weekday: "short" });
      dayText = eventDate.getDate().toString();
    }

    return (
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Typography variant="overline" color="primary.main">
          {monthText}
        </Typography>
        <Typography variant="h6"  color="primary.main" sx={{py: 0, my: 0, lineHeight: "normal" }}>
          {dayText}
        </Typography>
      </Stack>
    );
  }


  const StyledLocation = (): ReactElement => {
    // If the event is held at multiple locations...
    let locationName = "";

    if (event.isGolf) {
      // Is the event played at multiple courses?
      if (event.courseIds && event.courseIds.length > 1) {
        if (!event.courseIds.every( (val, i, arr) => val === arr[0])) {
          locationName = "Multiple Courses";
        } else {
          locationName = courses ? courses.find(c => c.itemId === event.courseIds?.[0])?.name ?? "" : "";
        }
      } else {
        locationName = courses ? courses.find(c => c.itemId === event.courseId)?.name ?? "" : "";
      }
    } else {
      locationName = event.socialLocation;
    }

    return (
      <Typography variant="caption" color="primary.main" sx={{py: 0, my: 0, lineHeight: 1 }}>
        {locationName}
      </Typography>
    );
  }

  const StyledAttendance = (): ReactElement => {
    return (
      isAttending ? 
        <Typography variant="caption" color="primary.light">{ IsEventOver(event.endTime) ? "You attended" : "You are attending" }</Typography> :
        <></>
    );
  }

  return (
    <Grid item container 
      component={NavLink} 
      to={`/event/${event.itemId}`}
      state={{isNewEvent: false}}
      color="primary.main" 
      xs={12} 
      alignItems="center" 
      sx={{py: 4, borderBottom: 1, borderColor: "primary.outlineVariant", textDecoration: 'inherit'}}>
      <Grid item xs={4} sm={4} md={3} justifyContent="flex-start" alignItems="center">
        <StyledDate />
        <Container sx={{ textAlign: "center", width: "100%", display: { xs: "block", sm: "none"}}}>
          <StyledTime />
        </Container>
      </Grid>
      <Grid item sm={2} md={2} justifyContent="flex-start" sx={{ display: { xs: "none", sm: "block"}}}>
        <StyledTime />
      </Grid>
      <Grid item aria-label="view event details" xs={6} sm={4} md={5} justifyContent="flex-start" style={{ }}>
        <Stack direction="column" spacing={4}>
          <Typography variant="overline" color="secondary.light" sx={{py: 0, my: 0, lineHeight: 1 }}>
            {event.leagueName}
          </Typography>
          {event.name !== event.leagueName &&
            <Typography variant="overline" color="primary.main" sx={{py: 0, my: 0, lineHeight: 1 }}>
              {event.name}
            </Typography>}
          <StyledLocation />
          <Container sx={{ display: { xs: "block", sm: "none"}}}>
            <StyledAttendance/>
          </Container>
        </Stack>
      </Grid>
      <Grid item sm={2} justifyContent="flex-start" sx={{ display: { xs: "none", sm: "block"}}}>
        <StyledAttendance />
      </Grid>
    </Grid>
  );
}