import { ReactElement } from "react";

import { ref } from "firebase/storage";
import { useDownloadURL } from "react-firebase-hooks/storage";
import { NavLink } from "react-router-dom";

import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";


import { imagesRef } from "../firebase";
import Course from '../types/Course';

interface CourseRowProps {
  course: Course,
  actions?: ReactElement<typeof Stack>
}

export const CourseRow = (props: CourseRowProps ) : ReactElement => {

  const course = props.course;
  const [imageUrl] = useDownloadURL((course && course.logo && course.logo.length > 0) ? ref(imagesRef, course.logo) : null);

  const StyledName = (): ReactElement => {
    return (
      <>
        <Grid item xs={12} m={4} sx={{py: 0, my: 0}}>
          <Box textAlign="left" alignItems="flex-start">
            <Typography variant="h6" color="primary.dark" sx={{py: 0, my: 0, lineHeight: "normal" }}>
              {course.name}
            </Typography>
          </Box>
        </Grid>
      </ >
    );
  }

  const StyledBasicInfo = (): ReactElement => {
    return (
      <Grid item container xs={6} justifyContent="flex-end">
        <Grid item xs={12}>
          <Typography variant="overline" color="primary" sx={{py: 0, my: 0, lineHeight: 1 }}>
            {"Par: " + course.par}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" color="primary" sx={{py: 0, my: 0, lineHeight: 1 }}>
            {"Tee time spacing: " + course.teeTimeSpacing}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid item container  key={course.itemId} xs={12} alignItems="center" component={NavLink} to={`/course/${course.itemId}`} state={{isNewCourse: false}} sx={{py: 4, borderBottom: 1, textDecoration: 'inherit'}}>
      <Grid item xs={2} justifyContent="center" sx={{ display: { xs: "none", sm: "flex"}}}>
        <Avatar 
          alt={course.name} 
          src={imageUrl}>
          <GolfCourseIcon />
        </Avatar>
      </Grid>      
      <Grid item container xs={5}>
        <StyledName />
      </Grid>
      <Grid item container aria-label="view a person's basic info" xs={7} sm={5} style={{ }}>
        <StyledBasicInfo />
      </Grid>
      {props.actions &&
        <Grid item xs={12}>
          {props.actions}
        </Grid>
      }
    </Grid>
  );
}