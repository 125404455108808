import { 
  ReactElement, 
  useEffect, 
  useMemo, 
  useRef,
  useState 
} from 'react';

import parse from 'autosuggest-highlight/parse';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';

import GooglePlace from '../types/GooglePlace';
import { loadPlacesApi } from '../utils/GoogleMapsUtils';

interface GooglePlaceFieldProps {
  placeId?: string;
  placeName?: string;
  placeUpdatedCallback(place: GooglePlace | null): void;
}

export default function GooglePlaceField(props: GooglePlaceFieldProps) : ReactElement {  
  const [placeDetails, setPlaceDetails] = useState<GooglePlace | null>(null);
  const [lastPlaceDetails, setLastPlaceDetails] = useState<GooglePlace | null>(null);
  const [inputFieldPlace, setInputFieldPlace] = useState(props.placeName ? props.placeName : '');
  const [options, setOptions] = useState<readonly GooglePlace[]>([]);
  const [autocompleteService, setAutocompleteService] = useState(null);
  const isPlacesAPILoaded = useRef(false);

  if (typeof window !== 'undefined' && !isPlacesAPILoaded.current) {
    if (!document.querySelector('#google-places-script')) {
      const googlePlacesScript = loadPlacesApi(true);
      googlePlacesScript.addEventListener('load', function () {
        isPlacesAPILoaded.current = true;
      });
    }
  }

  const fetch = useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly GooglePlace[]) => void,
        ) => {
          (autocompleteService as any).getPlacePredictions(
            request,
            callback,
          );
        },
        400,
      ),
    [autocompleteService],
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService && (window as any).google) {
      setAutocompleteService(new (
        window as any
      ).google.maps.places.AutocompleteService());
    }

    if (autocompleteService !== null) {
      fetch({ input: inputFieldPlace }, (results?: readonly GooglePlace[]) => {
        if (active) {
          let newOptions: readonly GooglePlace[] = [];

          if (placeDetails) {
            newOptions = [placeDetails];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }
          setOptions(newOptions);
        }
      })
    }

    return () => {
      active = false;
    };
  }, [isPlacesAPILoaded, autocompleteService, placeDetails, inputFieldPlace, fetch]);

  useEffect(() => {
    if (placeDetails !== lastPlaceDetails) {
      props.placeUpdatedCallback(placeDetails);
      setLastPlaceDetails(placeDetails);
    }
  }, [placeDetails, props]);

  return (
      <Autocomplete
        id="google-place-autocomplete"
        sx={{ width: '300' }}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.structured_formatting.main_text
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        clearOnBlur
        includeInputInList
        selectOnFocus
        filterSelectedOptions
        value={placeDetails}
        noOptionsText="No locations"
        onChange={(event: any, newPlace: GooglePlace | null) => {
          setOptions(newPlace ? [newPlace, ...options] : options);
          setPlaceDetails(newPlace);
        }}
        onInputChange={(event, newInputFieldPlace) => {
          setInputFieldPlace(newInputFieldPlace);
        }}
        renderInput={(params) => (
          <TextField {...params} label={props.placeName ? props.placeName : 'Add a location'} fullWidth />
        )}
        renderOption={(props, option) => {
          const matches =
            (option.structured_formatting && option.structured_formatting.main_text_matched_substrings) || [];

          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match: any) => [match.offset, match.offset + match.length]),
          );

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <LocationOnIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                  {parts.map((part, index) => (
                    <Box
                      key={index}
                      component="span"
                      sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                    >
                      {part.text}
                    </Box>
                  ))}
                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    )
  }