import { 
  ReactElement
} from 'react';

import { NavLink } from "react-router-dom";

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';

import { GhinCsvUpdate } from '../components/GhinCsvUpdate';
import { PersonDirectory } from '../components/PersonDirectory';
import { useData } from "../DataProvider";
import { 
  fabStyles, 
  tabStyles 
} from "../styles/styles";

export default function Guests(): ReactElement {
  const { adminOrgs } = useData();

  if (adminOrgs.length > 0) {
    return (
      <Box sx={tabStyles}>
        <PersonDirectory isActive={false}/>
        <GhinCsvUpdate />
        <Fab variant="extended" color="primary" aria-label="invite guest" component={NavLink} to="/invite-guests" sx={fabStyles}>
          <AddIcon />
          Add guest
        </Fab>
      </Box>
    );
  } else {
    return (
      <></>
    );
  }  
}