import { ReactElement } from "react";

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

export const Tournaments = (): ReactElement => {
  return (
<Container>
  <Typography variant="h6">
    Club Championship, Partnership and Member/Guest Tournaments
  </Typography>
  <Typography variant="body1">
    These events are open to all SWGA members in good standing who have made an obvious attempt to play in the weekly competitions and other club events on a regular basis.
  </Typography>
  <Typography variant="body1">
    <p>
      <b>The club championship</b> is a two-day event with drinks and refreshments following play on the last day. The Gross Club Champion is the player with the lowest two-day total gross score. The Net Club Champion is the player with the lowest two-day total net score. Equal low-gross and low-net awards are given in two flights. In the event of a tie for either club championship, a sudden-death play off will occur immediately after play. All other ties will be broken using the back-nine method previously described under “Ties.”
    </p>
    <p>
      <b>The partnership tournament</b> is a two-day member/member event with a potluck lunch following play on day 2. For the partnership tournament, there shall be no more than a 10-point handicap spread between partners. Prizes will be awarded based on net scoring in two flights.
    </p>
    <p>
      <b>The member/guest tournament</b> is a two-day member/guest event with a luncheon following play on day 2. There shall be no more than a 20-point handicap spread between partners. Prizes will be awarded based on both gross and net scoring in two flights.
    </p>
  </Typography>
  <Typography variant="body1">
    <p>
      In accordance with OGA rules, prizes for these tournaments will be gift cards or store credit. Cash will not awarded.
    </p>
  </Typography>
</Container>
);
}