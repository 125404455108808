import React, { 
  ReactElement, 
  useEffect, 
  useRef,
  useState
} from "react";

import { 
  collection, 
  orderBy, 
  Query, 
  query, 
  where 
} from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material-next/Button";

import { useData } from "../DataProvider";
import { firestore } from "../firebase";
import { listStyles, sectionStyles } from "../styles/styles";
import League from "../types/League";
import Membership from "../types/Membership";
import Person from "../types/Person";

import PersonAutocomplete from "./PersonAutocomplete";

type LeagueMembersProps = {
  league: League;
  isEditable?: boolean;
};

export default function LeagueMembers(props: LeagueMembersProps ): ReactElement {
  const isEditable = props.isEditable;
  const leagueId = props.league.itemId;

  const { addLeagueMembership } = useData();
  const personSelected = useRef<Person | null>(null);
  const [addPersonFieldValue, setAddPersonFieldValue] = useState<Person | null>(null);

  const qMembership = query(
    collection(firestore, "org/zRQde8GJXIflkGlRSwpU/membership/"),
    where("leagues", "array-contains", leagueId),
    orderBy("name")
  ) as Query<Membership>;

  const [memberships, isMembershipsLoading, isMembershipsError] = useCollectionData<Membership>(qMembership);

  console.log("League Name: " + props.league.name);

  useEffect (() => {
    if (isMembershipsLoading) {
      console.log("Loading memberships");
    } else if (memberships) {
      console.log("Loaded " + memberships.length + " memberships");
    } else if (isMembershipsError) {
      console.log("Error loading memberships: " + isMembershipsError);
    }
  }, [memberships, isMembershipsLoading, isMembershipsError]);

  const handleSelectionChange = (event: React.SyntheticEvent, option: Person | null, /* reason: string */) => {
    if (option !== null) {
      console.log("Selected in Attendeelist: " + option?.name)
    } else {
      console.log("Selected in Attendeelist: null")
    }
    personSelected.current = option;
  }

  const handleAddPerson = () => {
    if (personSelected.current !== null) {
      console.log("Adding person: " + personSelected.current.name);
      addLeagueMembership(leagueId, personSelected.current.itemId);
      setAddPersonFieldValue(null);
    }
  }

  interface MembershipRowProps {
    membership: Membership;
  }

  // For MemberMember events, assumes that if a partnership has been established, 
  // the attendee has the partner info and the partner may not have registered or confirmed the partnership.
  const MembershipRow = (props: MembershipRowProps): ReactElement => {
    return (
      <Typography sx={listStyles}>
        {props.membership.name}
      </Typography>
    );
  }

  const FormattedEntries = (): ReactElement => {
    if (!isMembershipsLoading) {
      return (
        <Box sx={{ ...sectionStyles, flexGrow: 1 }}>
          <Grid container>
            {memberships && memberships.map((membership: Membership) =>
              <Grid item key={"member" + membership.itemId} xs={12}>
                <MembershipRow membership={membership}/>
              </Grid>
            )}
          </Grid>
          {isEditable &&
            <Box sx={listStyles} >
              <Grid container width="100%" alignItems="center" spacing="4">
                <Grid item xs={9}>
                  <PersonAutocomplete 
                    id="addPerson" 
                    size="small" 
                    label="Member to add"
                    selectedPersonId={addPersonFieldValue ? addPersonFieldValue.itemId : ""}
                    handleOnChange={handleSelectionChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button 
                    type="button" 
                    variant="outlined" 
                    color="secondary" 
                    size="medium" 
                    onClick={() => handleAddPerson()}>
                      Add
                  </Button>
                </Grid>
              </Grid>
            </Box>}
        </Box>
      );
    } else {
      return <Box />;
    }

  }
    
  return <FormattedEntries />;
}
