import { 
  ReactElement,  
  useState,
} from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Button from "@mui/material-next/Button";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useData } from "../DataProvider";
import { 
  selectedGridStyles,
  unselectedGridStyles } from "../styles/styles";
import Season from "../types/Season";
import { 
  FromDayJS,
  ToDayJS 
} from "../utils/utils";

interface SeasonEditorProps {
  season: Season;
  onChange?: (value: Season, reason: string) => void;
}

export default function SeasonEditor(props: SeasonEditorProps): ReactElement {  
  const { isLeagueAdmin, isOrgAdmin } = useData();
  const [season, setSeason] = useState<Season>(props.season);
  const [deleteConfirm, setDeleteConfirm] = useState<string>("");

  const handleSeasonDelete = () => {
    if (props.onChange) {
      props.onChange(season, "delete");
    }
  };

  const handleSeasonChange = (changes: Partial<Season>) => {
    setSeason({...season, ...changes});
  };

  // TODO: This writes to firestore too often because onBlur occurs when Picker appears.
  const onBlur = (reason: string) => {
    if (props.onChange) {
      if ((props.season.name !== season.name) ||
          !(props.season.startTime.isEqual(season.startTime)) ||
          !(props.season.endTime.isEqual(season.endTime))) {
        console.log("Change: ", season.startTime.isEqual(props.season.startTime))
        props.onChange(season, reason);
      }
    // TODO: Uncontrolled.  
    } else {
      console.log("No onChange handler");
    }
  };

  if (season && (isOrgAdmin(season.orgId) || isLeagueAdmin(season.leagueId))) {
    return (
      <Grid item container 
        background-color="blue" 
        key={season.itemId} 
        xs={12} 
        spacing={2} 
        sx={deleteConfirm === season.itemId ? selectedGridStyles : unselectedGridStyles}
        onBlur={() => onBlur("update")}
      >
        <Grid item key={season.itemId} xs={3} sm={5}>   
          <TextField 
            fullWidth
            label="Name" 
            variant="outlined" 
            value={season.name}
            onChange={(e) => handleSeasonChange({name: e.target.value})}
          />
        </Grid>
        <Grid item xs={4} sm={3}>
          <DatePicker
            label="Start date"
            aria-label="start date"
            aria-required="true"
            value={ToDayJS(season.startTime)}
            sx={{width: "100%"}}
            onAccept={(dayJS) => 
              setSeason(
                {...season,
                  startTime: FromDayJS(dayJS), 
                  endTime: FromDayJS(dayJS) > season.endTime ? FromDayJS(dayJS) : season.endTime
                }
              )
            }
          />
        </Grid>
        <Grid item xs={4} sm={3}>
          <DatePicker
            label="End date"
            aria-label="End date"
            aria-required="true"
            value={ToDayJS(season.endTime)}
            minDate={ToDayJS(season.startTime)}
            sx={{width: "100%"}}
            onAccept={(dayJS) => setSeason({...season, endTime: FromDayJS(dayJS)})}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton aria-label="delete" onClick={() => setDeleteConfirm(season.itemId)}>
            <DeleteIcon />
          </IconButton>
        </Grid>
        {deleteConfirm === season.itemId && (
          <Grid item xs={12}>
            <Button variant="outlined" size="large" onClick={() => handleSeasonDelete()}>
              Confirm Season Delete
            </Button>
            <Button size="large" color="secondary" onClick={() => setDeleteConfirm("")}>
              Cancel
            </Button>
          </Grid>
        )}
      </Grid>);
    } else {
      return (<Container />);
    }
}