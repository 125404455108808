import { 
  ReactElement, 
  useState 
} from "react";

import { NavLink } from "react-router-dom";

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import Stack from '@mui/material/Stack';
import Button from "@mui/material-next/Button";
import IconButton from '@mui/material-next/IconButton';

import { useData } from "../DataProvider";
import { cardStyles } from "../styles/styles";
import Event from '../types/Event';
import { CurrentDateTime } from "../utils/utils";

import EventHeader from "./EventHeader";
import EventRegBar from "./EventRegBar";

export default function EventCard(event:Event): ReactElement {
  const { deleteEvent, isOrgAdmin, isLeagueAdmin } = useData();

  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const deleteThisEvent = (id: string) => {
    console.log(`Deleting event ${id}`);
    deleteEvent(id);
    setDeleteConfirm(false);
  };

  const isSignupOpen = () => {
    const currentDateTime = CurrentDateTime();
    return (currentDateTime > event.signupOpensAt && currentDateTime < event.signupClosesAt);
  }

  return (
    <Card sx={cardStyles.card}>
      <CardActionArea component={NavLink} to={`/event/${event.itemId}`} state={{ eventId: event.itemId }}>
        <EventHeader event={event}/>
      </CardActionArea>
      <CardActions>
        <EventRegBar 
          event={event} 
          isSignupOpen={isSignupOpen()} 
          isFull={event?.registrationCount ? (event.maxPlayers !== 0 && (event.registrationCount>= event.maxPlayers)) : false}
        />
      </CardActions>
      {(isOrgAdmin(event.orgId) || isLeagueAdmin(event.leagueId)) && (
        <CardActions>
            <Stack direction="row" spacing={1}>
            <IconButton
              component={NavLink}
              to={`/event/${event.itemId}`}
              aria-label="edit"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={() => setDeleteConfirm(true)}
            >
              <DeleteIcon />
            </IconButton>
            {deleteConfirm && (
              <span>
                <Button
                  size="small"
                  onClick={() => deleteThisEvent(event.itemId)}
                >
                  Confirm
                </Button>
                <Button size="small" onClick={() => setDeleteConfirm(false)}>
                  Cancel
                </Button>
              </span>
            )}
            </Stack>
        </CardActions>
      )}
    </Card>
  );
}