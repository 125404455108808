import { 
  ReactElement, 
  useEffect, 
  useRef,
  useState 
} from "react";


import { 
  collection, 
  doc, 
  DocumentReference, 
  orderBy, 
  Query, 
  query, 
  Timestamp, 
  where 
} from "firebase/firestore";
import { 
  useCollectionData, 
  useDocumentData 
} from "react-firebase-hooks/firestore";

import CancelIcon from '@mui/icons-material/Cancel';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import EditIcon from '@mui/icons-material/Edit';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import SaveIcon from '@mui/icons-material/Save';
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import Button from "@mui/material-next/Button";

import { useData } from "../DataProvider";
import { firestore } from "../firebase";
import { 
  listStyles,
  sectionStyles,
  subtitleStyles 
} from "../styles/styles";
import Event from "../types/Event";
import Person from "../types/Person";
import Registration from "../types/Registration";
import { DisplayTime } from "../utils/utils";

import Hourglass from "./Hourglass";
import PersonAutocomplete from "./PersonAutocomplete";

export default function TeeSheets(props: { eventId: string, isEditMode?: boolean }): ReactElement {
  const eventId = props.eventId;
  const { addEventRegistration, isLeagueAdmin, updateEvent } = useData();

  const [isEditingTeesheet, setIsEditingTeesheet] = useState(false);
  const addPersonSelected = useRef<Person | null>(null);
  const [addPersonFieldValue, setAddPersonFieldValue] = useState<Person | null>(null);
  const [teesheets, setTeesheets] = useState<Map<string, string>>(new Map());
  const [event, isEventLoading, isEventError] = useDocumentData<Event>(doc(firestore, "event/" + eventId) as DocumentReference<Event>);

  const qReg = query(
    collection(firestore, "event/" + eventId + "/reg" ),
    where("isRegistered", "==", true),
    orderBy("playerName")
  ) as Query<Registration>;

  const [registrations, isRegLoading, isRegError] = useCollectionData<Registration>(qReg);

  const initializeTeeSheet = (storedTeeSheets: Record<string, string> | undefined): Map<string, string> => {
    const teesheets: Map<string, string> = new Map();

    if (storedTeeSheets) {
      for (const [key, value] of Object.entries(storedTeeSheets)) {
        teesheets.set(key, value);
      }
    }
    return teesheets;
  }

  useEffect (() => {
    if (isEventError) {
      console.log("Error loading event: " + isEventError);
    } else {
      if (isEventLoading) {
        console.log("Loading event");
      } else {
        if (event) {
          console.log("Event: " + event.name);
          setTeesheets(initializeTeeSheet(event.teesheets));
        }
      }
    }
  }, [event, isEventError, isEventLoading]);

  useEffect (() => {
    if (isRegError) {
      console.log("Error loading registrations: " + isRegError);
    } else {
      if (isRegLoading) {
        console.log("Loading registrations");
      } else {
        if (registrations) {
          console.log("Regs: " + registrations.length);
        }
      }
    }
    if (isRegLoading) {
      console.log("Loading registrations");
    }
  }, [registrations, isRegError, isRegLoading]);

  useEffect (() => {
    if (teesheets) {
      console.log("Teesheets: " + teesheets.size);
    }
  }, [teesheets]);

  const handleAddPerson = () => {
    if (event != null && addPersonSelected.current !== null) {
      console.log("Adding person: " + addPersonSelected.current.name);
      addEventRegistration(event.itemId, true, addPersonSelected.current);
      setAddPersonFieldValue(null);
    }
  }

  const findPlayer = (playerId: string): Registration | null => {
    if (registrations) {
      return registrations.find(reg => reg.playerId === playerId) || null;
    } else {
      return null;
    }
  }

  const teeTimeIndex = (day: number, group: number, position: number): string => {
    return (day + "-" + group + "-" + position);
  } 

  const fillSpot = (day: number, group: number, position: number): Registration | null => {
    if (registrations && teesheets) {
      const playerId = teesheets.get(teeTimeIndex(day, group, position));
      if (playerId) {
        const reg = findPlayer(playerId.toString());
        if (reg) {
          console.log("Found: " + reg.playerName);
          return reg;
        } else {
          console.log("day: " + day + " group: " + group + " position: " + position + " not found.");
          return null;
        }
      } else { 
        return null;
      }
        //const player = registrations.find(reg => reg.group === group && reg.groupPosition === position);
        //  }
    } else {
      console.log("Registrations not loaded");
      return null;
    }
  }

  const handleAddSelectionChange = (event: React.SyntheticEvent, option: Person | null, /* reason: string */) => {
    if (option !== null) {
      console.log("Selected in Attendeelist: " + option?.name)
    } else {
      console.log("Selected in Attendeelist: null")
    }
    addPersonSelected.current = option;
  }

  const handleSelectionChange = (day: number, group: number, position: number, value: Registration | null) => {
    const updatedKey = teeTimeIndex(day, group, position);
    if (value) {
      teesheets?.set(updatedKey, value?.playerId);
    // A person has been removed from the tee sheet.
    } else {
      const updatedSheet = [...teesheets.entries()].filter(([key /*, value*/]) => key !== updatedKey);
      setTeesheets(new Map(updatedSheet));
    }
  }

  const handleSubmit = () => {
    console.log("Saving pairings");
    try {
      if (teesheets) {
        updateEvent(eventId, {teesheets: Object.fromEntries(teesheets), isTeeSheetCreated: true});
        setIsEditingTeesheet(false);
      }
    } catch (error) {
      console.log("Error updating pairings: " + error);
    }
  };

  const handleEditTeesheetCancel = () => {
    // revert the teesheet to the original state
    if (event && teesheets) {
      setTeesheets(initializeTeeSheet(event.teesheets));
    }
    setIsEditingTeesheet(false);
  }

  const TeesheetRow = (props: {reg : Registration | null}): ReactElement => {
    if (props.reg) {
      // TODO: Generalize with Attendee Row
      return (
        <Stack alignItems="center" direction="row" gap={2}>
          {!props.reg.isPlayingGame && <MoneyOffIcon />}
          <Typography variant="body1">
            {props.reg.playerName + (props.reg.isEighteen ? "" : " (9)")}
          </Typography>
          {!props.reg.isRiding && <DirectionsWalkIcon />}
        </Stack>

      );
    } else {
      return (
        <Typography variant="body1">
          ---
        </Typography>
      );
    }
  }

  const TeesheetGrid = (props: {numGroups: number, numInGroup: number}): ReactElement => {
    const day = 0;
    return (
      <Grid container justifyContent="center" spacing={2}>
      {Array.from ({length: props.numGroups}, (_, group) => (
        <Grid container spacing={2} key={"group" + group} justifyContent="flex-start" sx={{pb: 2}}>
          <Grid item xs={3}>
            <Paper
              sx={{
                p: 4,
                height: "100%",
                bgcolor: "info.light",
                color: "info.contrastText",
              }}
            >
              { event &&
                <Typography variant="body1">
                  {DisplayTime(Timestamp.fromMillis(event.datetime.toMillis() + (600000*(event.isShotgun ? 0 : group))), "America/Los_Angeles")}
                </Typography>
              }
            </Paper>
          </Grid>
          <Grid container item xs={9}>
          {Array.from ({length: props.numInGroup}, (_, position) => (
            <Grid item xs={12} key={"group" + group + "pos" + position}>
              {registrations && 
                isEditingTeesheet ? 
                  <Autocomplete 
                    onChange={(event, value) => {
                      handleSelectionChange(day, group, position, value);
                    }}
                    disablePortal
                    id={"group" + group + "position" + position}
                    defaultValue={fillSpot(day, group, position)}
                    options={registrations}
                    getOptionLabel={(option: Registration) => option.playerName}
                    renderOption={(props: object, option: Registration /*, state: object*/) => (
                      <div {...props} >{option.playerName + (option.isEighteen ? "" : " (9)")}</div>)}
                    renderInput={(params) => <TextField {...params} label="Name" />}
                  /> 
                :
                  <TeesheetRow reg={fillSpot(day, group, position)} />
              }
            </Grid>
          ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
    );
  }
  if (!isEventLoading && event) {
    return (
      <Box 
        component="form"
        sx={{...sectionStyles, flexGrow: 1}}
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
      }}>
        <Typography color="primary.main" variant="h5" component="h2" sx={subtitleStyles} gutterBottom>
          Tee Sheet
        </Typography>
        <Grid container justifyContent="center" spacing={2}>
          {             
            <Grid item xs={12}>
              <TeesheetGrid numGroups={event.numTeeTimes} numInGroup={4} />
              <Grid item xs={12}>
                <Typography variant="body1">
                  (9) = 9 holes
                </Typography>
                <Stack direction="row" spacing={2} color="">
                  <DirectionsWalkIcon />
                  <Typography variant="body1">
                    = Walking
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          }
        </Grid>
        {isLeagueAdmin(event.leagueId) &&
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              { isEditingTeesheet ? 
                <>
                  <Button type="submit" key="formSubmit" size="medium" variant="filled" color="primary" startIcon={<SaveIcon />}>
                    Save
                  </Button>
                  <Button type="button" key="formCancel" size="medium" variant="outlined" color="secondary" startIcon={<CancelIcon />} onClick={() => handleEditTeesheetCancel()}>
                    Cancel
                  </Button>
                </> : 
                <Button type="button" key="formEdit" size="medium" variant="filled" color="primary" startIcon={<EditIcon />} onClick={() => setIsEditingTeesheet(true)}>
                  Edit
                </Button>
              }
            </Stack>
            <Box sx={listStyles}>
              <Grid container justifyContent="flex-start" spacing={2} alignItems="center">
                <Grid item xs={9}>
                  <PersonAutocomplete 
                    id="addPerson" 
                    size="small" 
                    label="Person to add"
                    selectedPersonId={addPersonFieldValue ? addPersonFieldValue.itemId : ""}
                    handleOnChange={handleAddSelectionChange}/>
                </Grid>
                <Grid item xs={3}>
                  <Button 
                    type="button" 
                    variant="outlined" 
                    color="secondary" 
                    size="medium" 
                    onClick={() => handleAddPerson()}>
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>  
        }
      </Box>
    );
  } else {
    return (<Hourglass />);
  }
}