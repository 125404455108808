import { 
  ReactElement, 
  useEffect, 
  useState } from "react";

import { ref } from "firebase/storage";
import { useDownloadURL } from "react-firebase-hooks/storage";

import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useAuth } from "../AuthProvider";
import Person from '../types/Person';
import {
  FormatPhoneNumber, 
  GetFirstAndLastName 
} from "../utils/utils";

interface PersonRowProps {
  person: Person,
  checkEmail?: boolean,
  isActive?: boolean,
  actions?: ReactElement<typeof Stack>
}

export const PersonRow = (props: PersonRowProps ) : ReactElement => {
  const { checkEmailAddressExists, imagesRef } = useAuth();

  const person = props.person;
  const [imageUrl, isImageLoading] = useDownloadURL((person && person.picture && person.picture.length > 0) ? ref(imagesRef, person.picture) : null);
  const [isEmailAvailable, setIsEmailAvailable] = useState(true);

  useEffect (() => {
    if (person.email === "" || !props.checkEmail) {
      return;
    } else {
      const checkEmailExists = async () => {
        const isExists = await checkEmailAddressExists(person.email);
        setIsEmailAvailable(!isExists);
      }
      checkEmailExists();
    }
  }, [person.email, props.checkEmail, checkEmailAddressExists]);

  const StyledName = (): ReactElement => {
    const [firstName, lastName] = GetFirstAndLastName(person.name);
    
    return (
      <>
        <Grid item xs={12} m={4} sx={{py: 0, my: 0}}>
          <Box textAlign="left" alignItems="flex-start">
            <Typography variant="h6" color="primary.dark" sx={{py: 0, my: 0, lineHeight: "normal" }}>
              {firstName}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} m={4} sx={{py: 0, my: 0}}>
          <Box textAlign="left" alignItems="flex-end">
            <Typography color="primary.dark" variant="overline">
              {lastName}
            </Typography>
          </Box>
        </Grid>
      </ >
    );
  }

  const StyledBasicInfo = (): ReactElement => {
    return (
      <Grid item container xs={6} justifyContent="flex-end">
        <Grid item xs={12}>
          <Typography variant="overline" color="primary" sx={{py: 0, my: 0, lineHeight: 1 }}>
            {person.email}
          </Typography>
        </Grid>
        { person.phone &&
          <Grid item xs={12}>
            <Typography variant="caption" color="primary" sx={{py: 0, my: 0, lineHeight: 1 }}>
              {FormatPhoneNumber(person.phone)}
            </Typography>
          </Grid>
        }
        { person.ghin && person.ghin !== "" &&
          <Grid item xs={12}>
            <Typography color="primary" variant="caption" >
              {"GHIN: " + person.ghin + ", Index: " + person.index}
            </Typography>
          </Grid>
        }
      </Grid>
    );
  }
// something to insert later: <Grid item container component={NavLink} to="/person" state={{personId: person.itemId}} xs={4}></Grid>

  return (
    <Grid item container      
      xs={12} alignItems="center" 
      sx={{py: 4, borderBottom: 1, textDecoration: 'inherit'}}>
      <Grid item xs={2} justifyContent="center" sx={{ display: { xs: "none", sm: "flex"}}}>
        <Avatar 
          alt={person.name} 
          src={(!isImageLoading && imageUrl) ? imageUrl : ""}
        />
      </Grid>
      <Grid item container xs={4}>
        <StyledName />
      </Grid>
      <Grid item container aria-label="view a person's basic info" xs={8} sm={6} style={{ }}>
        <StyledBasicInfo />

        { props.checkEmail && isEmailAvailable === false &&
          <Grid item xs={12}>
            <Alert severity="error" sx={{py: 0, px: 0, my: 0, lineHeight: 1 }}>
              This email address is in use.
            </Alert>
          </Grid>
        }
        {props.actions &&
          <Grid item xs={12} sx={{px: 0, py: 0, my: 0, lineHeight: 1 }}>
            {props.actions}
          </Grid>
        }

      </Grid>

    </Grid>
  );
}