import React, { 
  ReactElement, 
  useEffect, 
} from "react";

import { 
  collection, 
  Query, 
  query,
  where
} from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";

import { firestore } from '../firebase';
import { 
  bodyStyles, 
  subtitleStyles
} from "../styles/styles";
import { CompDenomination } from "../types/Competition";
import Score from "../types/Score";
import Season from "../types/Season";

type LeagueResultsProps = {
  leagueId: string;
  orgId: string;
  memberId?: string;
};

export default function LeagueResults(props: LeagueResultsProps ): ReactElement {
  const memberId = props.memberId;

  const qResults = query(
    collection(firestore, 
      "org", props.orgId, 
      "season"), 
      where ("leagueId", "==", props.leagueId),
  ) as Query<Season>;

  const [seasons, isSeasonsLoading, seasonsError] = useCollectionData<Season>(qResults);

  const baseTextStyle: React.CSSProperties = {
    fontSize: ".8rem", // Adjust as needed
    display: 'flex',
    overflowWrap: 'break-word',
    justifyContent: 'center',
    alignItems: 'center',
  };

  useEffect (() => {
    if (seasons) {
      console.log("Seasons loaded.");
    } else if (seasonsError) {
      console.log("Error loading results: " + seasonsError.message);
    } else if (isSeasonsLoading) {
      console.log("Loading results.");
    }
  }, [seasons, seasonsError, isSeasonsLoading]);

  function checkPerson (score: Score): boolean {
    return (memberId ? score.playerId === memberId : true);
  }

  const HoleScore = (props: {score: number, par: number, hole: number}): ReactElement => {
    const score = props.score;
    const par = props.par;

    const difference = score - par;

    let shapeStyle: React.CSSProperties;
    let innerShapeStyle: React.CSSProperties | undefined = undefined;
    let innerInnerShapeStyle: React.CSSProperties | undefined = undefined;

    if (difference === 1) { // Bogey
      shapeStyle = { ...baseTextStyle, border: ".05rem solid Tomato", margin: ".3rem", padding: ".1rem", width: "1rem", height: "1rem" };
    } else if (difference === 2) { // Double Bogey
      shapeStyle = { ...baseTextStyle, border: ".05rem solid Tomato", margin: ".2rem", padding: ".1rem", width: "1.2rem", height: "1.2rem" };
      innerShapeStyle = { ...baseTextStyle, border: ".05rem solid Tomato", padding: ".1rem", width: "1rem", height: "1rem" };
    } else if (difference >= 3) {
      shapeStyle = { ...baseTextStyle, border: ".05rem solid Tomato", margin: ".1rem", padding: ".1rem", width: "1.4rem", height: "1.4rem" };
      innerShapeStyle = { ...baseTextStyle, border: ".05rem solid Tomato", padding: ".1rem", width: "1.2rem", height: "1.2rem" };
      innerInnerShapeStyle = { ...baseTextStyle, border: ".05rem solid Tomato", padding: ".1rem", width: "1rem", height: "1rem" };

    } else if (difference === -1) { // Birdie
      shapeStyle = { ...baseTextStyle, border: ".05rem solid LimeGreen", borderRadius: '50%', margin: ".3rem", padding: ".1rem", width: "1rem", height: "1rem" };
    } else if (difference === -2) { // Eagle
      shapeStyle = { ...baseTextStyle, border: ".05rem solid LimeGreen", borderRadius: '50%', margin: ".2rem", padding: ".1rem", width: "1.2rem", height: "1.2rem" };
      innerShapeStyle = { ...baseTextStyle, border: ".05rem solid LimeGreen", borderRadius: '50%', padding: ".1rem", width: "1rem", height: "1rem" };
    } else if (difference === -3) { // Double Eagle (Albatross)
      shapeStyle = { ...baseTextStyle, border: ".05rem solid LimeGreen", borderRadius: '50%', margin: ".1rem", padding: ".1rem", width: "1.4rem", height: "1.4rem" };
      innerShapeStyle = { ...baseTextStyle, border: ".05rem solid LimeGreen", borderRadius: '50%', padding: ".1rem", width: "1.2rem", height: "1.2rem" };
      innerInnerShapeStyle = { ...baseTextStyle, border: ".05rem solid LimeGreen", borderRadius: '50%', padding: ".1rem", width: "1rem", height: "1rem" };
    } else { // Par or other scores
      shapeStyle = { ...baseTextStyle, margin: ".3rem", padding: "0rem", width: "1rem", height: "1rem" }; 
    }

    return (
      <Box>
        {innerInnerShapeStyle ? (
          <Box sx={shapeStyle}>
            <Box sx={innerShapeStyle}>
              <Box sx={innerInnerShapeStyle}>
                {score}
              </Box>
            </Box>
          </Box>
        ) : innerShapeStyle ? (
          <Box sx={shapeStyle}>
            <Box sx={innerShapeStyle}>
              {score}
            </Box>
          </Box>
        ) : (
          <Box sx={shapeStyle}>
            {score}
          </Box>
        )}
      </Box>
    );
  };

  /*
  const MemberRow = (member: Person): ReactElement => {
    return (
      <Grid item xs={12}>
        <Typography>{member.name}</Typography>
      </Grid>
    );
  }
*/
const calculateTotal = (scores: number[]) => {
  return scores.reduce((total, score) => total + score, 0);
};

  const ScorecardHeader = (props: {parByHole?: number[], tee?: string, denomination?: CompDenomination}): ReactElement => {
    const parByHole = props.parByHole ? props.parByHole : [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];

    if (props.denomination === CompDenomination.Cash) { 
      return (
        <Grid 
        container 
        wrap="wrap"
        sx={{backgroundColor: 'primary.container',
        color: 'primary.onContainer',
        flexGrow: 1}}
        >
          <Grid alignContent="center" justifyContent="flex-end" xs={3} sm={2} lg={1} sx={{ fontWeight: 'bold' }}>
          </Grid>
          <Grid container xs={9} sm={10} lg={11}>
            <Typography variant="body1" sx={{...baseTextStyle, ml: "1rem", fontWeight: 'bold', width: "1rem", height: "1.2rem"}}>
              Cash
            </Typography>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid 
          container 
          wrap="wrap"
          sx={{backgroundColor: 'primary.container',
          color: 'primary.onContainer',
          flexGrow: 1}}
        >
          <Grid alignContent="center" justifyContent="flex-end" xs={3} sm={2} lg={1} sx={{ fontWeight: 'bold' }}>
            <Typography variant="body1" sx={{...baseTextStyle, ml: "1rem", fontWeight: 'bold', width: "1rem", height: "1.2rem"}}>
              Holes
            </Typography>
          </Grid>
          <Grid container xs={9} sm={10} lg={11}>
            <Stack direction="row" alignItems="flex-start" justifyContent="center" spacing={{xs: 0, sm: 1}} display={{xs: "none", md: "flex"}}>
              {parByHole.map((par, hole) => (
                <Stack key={hole+1} display="block">
                  <Typography variant="body1" display="inline-block" sx={{...baseTextStyle, mx: ".3rem", fontWeight: 'bold', width: "1rem", height: "1.2rem"}}>{hole + 1}</Typography>
                  <Typography variant="body1" display="inline-block" sx={{...baseTextStyle, mx: ".3rem", fontWeight: 'bold', width: "1rem", height: "1rem"}}>{par}</Typography>
                </Stack>
              ))}
              <Box sx={{ ...baseTextStyle, ml: "10px", fontWeight: 'bold', width: "30px", height: "50px" }}>
                Total
              </Box>
            </Stack>
            <Grid display={{xs: "flex", md: "none"}} spacing={{xs: 0, sm: 1}} > 
              <Grid xs={10} alignItems="flex-start" justifyContent="center" spacing={{xs: 0, sm: 1}} >
                <Stack key="f9" direction="row" alignItems="flex-start" justifyContent="center" spacing={{xs: 0, sm: 1}}  display={{xs: "flex", md: "none"}}>
                  {parByHole.slice(0,9).map((par, f9Hole) => (
                    <Stack key={f9Hole+1} display="block" sx={{ ...baseTextStyle, fontWeight: 'bold',}}>
                      <Typography variant="body1" display="inline-block" sx={{...baseTextStyle, mx: ".3rem", fontWeight: 'bold', width: "1rem", height: "1.2rem"}}>{f9Hole + 1}</Typography>
                      <Typography variant="body1" display="inline-block" sx={{...baseTextStyle, mx: ".3rem", fontWeight: 'bold', width: "1rem", height: "1.2rem"}}>{par}</Typography>
                    </Stack>
                  ))}
                </Stack>
                <Stack key="b9" direction="row" alignItems="flex-start" justifyContent="center" spacing={{xs: 0, sm: 1}} display={{xs: "flex", md: "none"}}>
                  {parByHole.slice(9,18).map((par, index) => (
                    <Stack key={index+9} display="block">
                      <Typography variant="body1" display="inline-block" sx={{...baseTextStyle, mx: ".3rem", fontWeight: 'bold', width: "1rem", height: "1.2rem"}}>{index + 10}</Typography>
                      <Typography variant="body1" display="inline-block" sx={{...baseTextStyle, mx: ".3rem", fontWeight: 'bold', width: "1rem", height: "1.2rem"}}>{par}</Typography>
                    </Stack>
                  ))}
                </Stack>
              </Grid>
              <Grid xs={2} direction="row" justifyContent="center" height="100%" alignItems="center" display={{xs: "flex", md: "none"}} sx={{ ...baseTextStyle, ml: "10px", fontWeight: 'bold', width: "30px", height: "30px" }}>
                Total
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }
  }

  const ScoreRow = (props: {name: string, scoreByHole: number[], totalScore?: number, parByHole?: number[], denomination?: CompDenomination, isOddRow: boolean}): ReactElement => {
    const name = props.name;
    const scoreByHole = props.scoreByHole;
    const parByHole = props.parByHole ? props.parByHole : [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    const denomination = props.denomination;
    const totalScore = props.totalScore ? props.totalScore : calculateTotal(scoreByHole);
    const isOddRow = props.isOddRow;

    if (denomination === CompDenomination.Cash) {
      return (
        <Grid 
          container 
          wrap="nowrap"
          sx={{backgroundColor: isOddRow ? 'primary.surface' : 'primary.surfaceVariant',
            color: isOddRow ? 'primary.onSurface' : 'primary.onSurfaceVariant',
            flexGrow: 1
          }}
        >
          <Grid alignContent="center" xs={3} sm={2} lg={1} sx={{ fontWeight: 'bold', wrap: "wrapWord"}}>
            {name}
          </Grid>
          <Grid xs={9} sm={10} lg={11}>
            <Typography variant="body1" sx={{...baseTextStyle, ml: "1rem", fontWeight: 'bold', width: "1rem", height: "1.2rem"}}>
              {totalScore ? totalScore : 0}
            </Typography>
          </Grid>
        </Grid>
      )
    } else {
    return (
      <Grid 
        container 
        wrap="nowrap"
        sx={{backgroundColor: isOddRow ? 'primary.surface' : 'primary.surfaceVariant',
          color: isOddRow ? 'primary.onSurface' : 'primary.onSurfaceVariant',
        flexGrow: 1
        }}
      >
        <Grid alignContent="center" xs={3} sm={2} lg={1} sx={{ fontWeight: 'bold', wrap: "wrapWord"}}>
          {name}
        </Grid>
        <Grid container xs={9} sm={10} lg={11}>
          <Stack direction="row" alignItems="flex-start" justifyContent="center" spacing={{xs: 0, sm: 1}}  display={{xs: "none", md: "flex"}}>
            {scoreByHole.map((score, index) =>
              <HoleScore key={index+1} score={score} par={parByHole ? parByHole[index] : 0} hole={index+1} />
            )}
            <Box sx={{ ...baseTextStyle, ml: "10px", fontWeight: 'bold', width: "30px", height: "30px" }}>
              {calculateTotal(scoreByHole)}
            </Box>
          </Stack>
          <Grid display={{xs: "flex", md: "none"}} spacing={1}> 
            <Grid xs={10} alignItems="flex-start" justifyContent="center" spacing={{xs: 0, sm: 1}} >
              <Stack direction="row" alignItems="flex-start" justifyContent="center" spacing={{xs: 0, sm: 1}}  display={{xs: "flex", md: "none"}}>
                {scoreByHole.slice(0, 9).map((score, index) =>
                  <HoleScore key={"f9" + index} score={score} par={parByHole ? parByHole[index] : 0} hole={index+1} />
                )}
              </Stack>
              <Stack direction="row" alignItems="flex-start" justifyContent="center" spacing={{xs: 0, sm: 1}}  display={{xs: "flex", md: "none"}}>
                {scoreByHole.slice(9,18).map((score, index) => (
                  <HoleScore key={"b9" + index} score={score} par={parByHole ? parByHole[index+9] : 0} hole={index+10} />
                ))}
              </Stack>
            </Grid>
            <Grid xs={2} direction="row" justifyContent="flex-end" height="100%" alignItems="center" display={{xs: "flex", md: "none"}} sx={{ ...baseTextStyle, ml: "10px", fontWeight: 'bold', width: "30px", height: "30px" }}>
              {calculateTotal(scoreByHole)}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
    }
  }

  const Standings = (props: {season: Season}) => {
    const season = props.season;
    console.log(season);
    if (season.standings && season.standings.length > 0) {
      return (
        <Grid container spacing={1} display="block" sx={{...bodyStyles}}>
        {season.standings.map((standing, index) =>
          standing.scores &&
            <Box key={standing.competition.name}>   
              <Typography variant="h6" sx={{...subtitleStyles, pl: 0}}>{standing.competition.name}</Typography>       
              <ScorecardHeader key={"header"+index} parByHole={standing.courseParByHole?.split(",").map(Number)} denomination={standing.competition.denomination}/>
              {standing.scores.filter(checkPerson).map((score, index) =>
                <ScoreRow 
                  key={"score" + score.playerId} 
                  name={score.playerName} 
                  scoreByHole={score.scoreByHole ? score.scoreByHole.split(",").map(Number) : [0]} 
                  parByHole={standing.courseParByHole?.split(",").map(Number)} 
                  totalScore={score.totalScore}
                  denomination={standing.competition.denomination}
                  isOddRow={index % 2 === 0}/>
              )}
            </Box>
          )}
        </Grid>
      );
    } else { 
      return <Box key={season.itemId}></Box>
    }
  };  

  if (!isSeasonsLoading && seasons) {
    return (
      <Box width="100%" sx={{ ...bodyStyles, flexGrow: 1}}>
        {seasons.map((season) => 
          <Standings key={"standings" + season.itemId} season={season} />
        )}
      </Box>
    );
  } else {
    return <> </>
  }
}

/* 
  return (
    <Box width="100%" sx={{ ...bodyStyles, flexGrow: 1}}>
      <Typography sx={{...subtitleStyles}} variant="h6">Meadows Eclectic Results (gross)</Typography>
      <CourseResults results={meadowsGrossResults} par={meadowsPar} tee="red"/>
      <Typography sx={{...subtitleStyles}} variant="h6">Meadows Eclectic Results (net)</Typography>
      <CourseResults results={meadowsNetResults} par={meadowsPar} tee="red"/>
      <Typography sx={{...subtitleStyles}} variant="h6">Woodlands Eclectic Results (gross)</Typography>
      <CourseResults results={woodlandsGrossResults} par={woodlandsPar} tee="red"/>
      <Typography sx={{...subtitleStyles}} variant="h6">Woodlands Eclectic Results (net)</Typography>
      <CourseResults results={woodlandsNetResults} par={woodlandsPar} tee="red"/>
    </Box>
  );*/