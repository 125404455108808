import { ReactElement } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function Hourglass(): ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const hourglass = require('../assets/hourglass.webp');

  return (
    <Box sx={{pt:10 }}>
      <Box component="img" src={hourglass} alt={"loading"} sx={{ pt: 10, width: "100%" }} />
      <Typography variant="body1" align="center" gutterBottom>
        Loading the cart...
      </Typography>
    </Box>);
}
    