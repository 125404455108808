import { 
  MouseEvent, 
  ReactElement, 
  useEffect 
} from "react";

import { sendEmailVerification } from "firebase/auth";
import { useNavigate } from "react-router";

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import { useAuth } from "../AuthProvider";
import { useData } from "../DataProvider";

export default function Verify(): ReactElement {
  const navigate = useNavigate();

  const { auth, isLoggingIn, isLoggedIn } = useAuth(); 
  const { userProfile } = useData();

  useEffect(() => {
    if (!isLoggingIn) {
      if (userProfile) {
        navigate("/");        }
    } else {
      navigate("/signup");
    }
  }, [isLoggingIn, navigate, userProfile]);

  /* const handleVerifyEmail = (auth: any, actionCode: any) => {
    applyActionCode(auth, actionCode!)
      .then((res) => {
        //SUCCESS: EMAIL VERIFIED
        console.log(res, 'user UID ?');
        // updateDoc(doc(db, 'Data', res.user.uid), {
        //   verifiedEmail: true,
        // });
      })
      .catch((error) => {
        console.log(error, 'Cannot verify email');
      });
  }; */

  const resendClickHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (auth.currentUser) {
      sendEmailVerification(auth.currentUser, {url:"https://swga.golf/emailverified?confirm_email=true"});
  }};

  return (
    <Container>
      {isLoggingIn && 
        <Box />
      }
      {!isLoggingIn && isLoggedIn && !userProfile &&
        <Box sx={{ p: 2 }}>
          <Alert severity="error" action={
            <Button variant='outlined' color="error" size="small"   
              onClick={resendClickHandler}
            >
              Resend
            </Button>}>
            <AlertTitle>Verify your Email</AlertTitle>
            An email was sent to you to verify your 
            email account is correct. Please go to your email and click the link. 
            If you need the email resent, click Resend.
          </Alert>
        </Box>
      }
    </Container>
  );
}
