import { 
  ReactElement
} from 'react';

import { NavLink } from "react-router-dom";

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import LeagueCard from '../components/LeagueCard';
import { useData } from '../DataProvider';
import { fabStyles, sectionStyles, titleStyles } from "../styles/styles";
import League from '../types/League';

export default function Leagues(): ReactElement {
  const { adminOrgs, isOrgAdmin, leagues } = useData();
  
  return (
    <Box sx={sectionStyles}>
      {leagues &&
        <>
          <Typography sx={titleStyles}>Leagues</Typography>
          <Grid container direction="row" spacing={1}>
            {leagues && leagues.flatMap((league: League) => {
              return (
                <Grid item key={"open" + league.itemId} xs={12} sm={6} md={4} minWidth={300}>
                  <LeagueCard  {...league} />
                </Grid>       
              )
            })}
          </Grid>
        </>
      }
      {adminOrgs.length > 0 && isOrgAdmin(adminOrgs[0]) &&
        <Fab variant="extended" color="primary" aria-label="add league" component={NavLink} to="/league-create" sx={fabStyles}>
          <AddIcon />
          Add league
        </Fab>
      }
    </Box>);
}