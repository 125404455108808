import { 
  ReactElement, 
  useEffect 
} from "react";

import { 
  collection, 
  orderBy, 
  Query, 
  query 
} from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { useAuth } from "../AuthProvider";
import { useData } from "../DataProvider";
import { firestore } from "../firebase";
import Event from "../types/Event";
import League from "../types/League";
import { IsEventOver } from "../utils/utils";

import EventRow from "./EventRow";

export default function EventSchedule(props: { isUpcoming: boolean }): ReactElement {
  const { isRevealingGodsSecrets } = useAuth();
  const { events } = useData();

  const qLeagues = query(
    collection(firestore, "org/zRQde8GJXIflkGlRSwpU/league" ),
    orderBy("name")
  ) as Query<League>;
  
  const [leagues, isLeaguesLoading, isLeaguesError] = useCollectionData<League>(qLeagues);

  useEffect(() => {
    if (leagues) {
      console.log("Loaded leagues");
    }
    if (isLeaguesLoading) {
      console.log("Loading leagues");
    }
    if (isLeaguesError) {
        console.log("Error loading leagues");
    }

  }, [leagues, isLeaguesLoading, isLeaguesError]);

  return (
    <Box>
      {events && leagues && (
        <Grid container sx={{ width: "100%", bgcolor: "background.paper" }}>
          { events.flatMap((event: Event) => {
            if ((isRevealingGodsSecrets || !event?.isTest) && IsEventOver(event.endTime) !== props.isUpcoming) {
              return (<EventRow key={event.itemId} {...event} />);
            } else {
              return null;
            }
          })}
        </Grid>
      )}
    </Box>
  );
}