import { ReactElement, useEffect } from "react";

import { useNavigate } from "react-router";

import Box from "@mui/material/Box";

import { useApp } from "../AppProvider";
import CourseEditor from "../components/CourseEditor";
import { useData } from "../DataProvider";
import Course from "../types/Course";
import { CurrentDateTime } from "../utils/utils";

export default function CourseCreatePage(): ReactElement {
  const { setPageName } = useApp();
  const { adminOrgs } = useData();

  const navigate = useNavigate();

  useEffect(() => {
    setPageName("Create Course");
  }, [setPageName]);

  const createNewCourse = (): Course => {
    const newCourse: Course = { 
      name: '',
      creatorId: '',
      createTime: CurrentDateTime(),
      isTest: false,
      itemId: '',
      logo: '',
      par: 72,
      shortName: '',
      teeTimeSpacing: 10,
      timezone: "America/Los_Angeles"
    };
    return newCourse;
  };

  const course = createNewCourse();

  if (adminOrgs.length > 0) {
    return (
      <Box>
        <CourseEditor course={course} isNewCourse={true} />
      </Box>
    );
  } else {
    navigate("/");
    return <></>;
  }
};
// && IsEventSignupOpen(event.signupOpensAt, event.signupClosesAt) && 
// {event.isGolf && IsEventUpcoming(event.datetime) && IsEventSignupClosed(event.signupClosesAt) &&