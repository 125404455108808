import { 
  Navigate, 
  useLocation 
} from 'react-router-dom';

import { useAuth } from "../AuthProvider";
import { useData } from "../DataProvider";

import Hourglass from './Hourglass';

export default function PrivateRoute(props: { children: React.ReactNode }) {
//export const PrivateRoute = (props: { children: React.ReactNode }): JSX.Element => {
  const { children } = props
  const { isLoggedIn, isLoggingIn } = useAuth();
  const { isUserActive, isUserLoading } = useData();

  const location = useLocation();

  return (
    isLoggingIn || isUserLoading ? (
      <Hourglass />
    ) : (
      isLoggedIn ? (
        isUserActive ? (
          <>{children}</>
        ) : (
          <Navigate
            replace={true}
            to="/loadingerror"
            state={{ from: `${location.pathname}${location.search}` }}
          />
        )
      ) : (
        <Navigate
          replace={true}
          to="/login"
          state={{ from: `${location.pathname}${location.search}` }}
        />
      )
    )
  );
}