// styles.ts
import { SxProps, Theme } from '@mui/material'

const navRailWidth = '120px';

export const cardStyles: Record<string, SxProps<Theme> | undefined> = {

  card: {
    borderRadius: 4,
    border: 1,
    borderColor: "primary.outline",
    m: 4
  },
  overline: {
    px: 4, 
    display: 'flex', 
    alignItems: 'right', 
    justifyContent: 'space-between',
    color: "primary.outlineVariant",
  },

  avatar: {
    width: [48,60], 
    height: [48,60], 
    transform: 'translateY(50%)',
    '& > img': {
      m: 0,
      color: 'secondary.onContainer',
      backgroundColor: 'secondary.container',
  }},
  avatarsm: {
      width: 60,
      height: 60,
  },
  header: {
    textTransform: 'uppercase', 
    fontSize: 14, 
    color: 'primary',
    letterSpacing: '1px',
    align: 'right',  
    pt: 12, 
  },
  divider: {
    backgroundColor: 'outlineVariant', 
    marginBottom: [1, 4],
  },
  tagline: { 
    textTransform: 'uppercase', 
    color: 'secondary.onContainer', 
    backgroundColor: 'secondary.container', 
    marginBottom: '0.35em', 
    fontWeight: 'bold', 
    letterSpacing: '2px',
    fontSize: 16, 
    align: 'right',
    pr: 4, 
  },
  title: {
    typography: "h6",
    fontWeight: 900, 
    mb: 3,
    color: 'primary'
  },
  subtitle: {
    typography: "h6",
    fontWeight: 500,
    mb: 3
  },
  location: {
    typography: "body1",
    fontWeight: 500,
    lineHeight: 1.25,
    mb: 2,
    color: 'primary'
  },
  description: {
    typography: "body1",
    lineHeight: 1.25,
    mb: 2
  },
  footer: {
    color: 'secondary',
  }
};

export const actionStyles = {
  pt: 1,
};

export const actionButtonStyles = {
  color: "primary.onContainer",
  backgroundColor: "primary.container"
};

export const actionTextButtonStyles = {

};


export const appStyles = {
  backgroundColor: 'primary.surface',
  color: 'primary.onSurface',
}

export const appBarStyles = {
  backgroundColor: 'secondary.surface',
  color: 'secondary.onSurface',
  display: "block", 
  typography: "h6",
  px: 3,
};

export const appContentStyles = {
  backgroundColor: 'primary.surface',
  color: 'primary.onSurface',
  display: "flex",
  width: {xs: '100vw', md: `calc(100vw - ${navRailWidth})`},
};


export const boxFormStyles = {
  py: 4,
  my: 4,
  mx: 2,
  borderRadius: 4,
  border: 1,
  borderColor: "outline",
  minWidth: 300,
  maxWidth: 650
};

export const boxStyles = {
  m: 1,
  border: 1,
  borderRadius: 1,
  padding: "16px",
  borderColor: "outlineVariant",
};


export const buttonStyles = {
  m: 4,
};


export const dividerStyles = {
  mt: 8,
  mb: 2,
  width: '100%',
  borderColor: 'primary.outlineVariant',
};

export const fabStyles = {
  position: 'fixed',
  bottom: '2rem',
  right: '2rem',
}

export const navRailStyles = {
  width: '120px',
  flexShrink: 0,
  overflow: 'auto',
  backgroundColor: 'secondary.surface',
  color: 'secondary.onSurface',
  '& .MuiDrawer-paper': {
    width: '120px',
    backgroundColor: 'secondary.surface',
    color: 'secondary.onSurface',
    boxSizing: 'border-box',
  },
  typography: "body2",
};

export const navRailItemStyles = {
  color: "secondary",
  typography: "body2",
  flexDirection: 'column', 
  width: '100%' 
};

export const selectedGridStyles = {
  backgroundColor: "primary.surfaceVariant"
}

export const unselectedGridStyles = {
  backgroundColor: "inherit"
}

export const sectionStyles = {
  px: 2,
  py: 8,
  backgroundColor: 'primary.surface',
  color: 'primary.onSurface'
};

export const tabStyles = {
  bt: 0,
  width: '100%',
  borderBottom: 1, 
  borderColor: 'primary.outlineVariant', 
  backgroundColor: 'secondary.container', 
  color: 'secondary.onContainer'
}

export const tabPanelStyles = {
  p: 0,
  width: '100%', 
  backgroundColor: 'primary.surface',
  color: 'primary.onSurface'
}

export const tabPanelInstructionsStyles = {
  p: 0,
  width: '100%', 
  backgroundColor: 'secondary.container',
  color: 'secondary.onContainer'
}

export const titleStyles = {
  color: "primary.onSurface",
  typography: "h5",
  fontSize: "1.5rem",
  pt: 2,
  pb: 2,
  px: 4,
  my: 2,
};

export const subtitleStyles = {
  color: "primary.main",
  typography: "h6",
  fontSize: "1.1rem",
  mt: 1,
  mb: 4,
  px: 4,
};

export const labelStyles = {
  color: "secondary.light",
  typography: "body1",
  px: 4,
  my: 2,
};

export const bodyStyles = {
  color: "secondary.main",
  typography: "body2",
  px: 4,
  py: 2,
  my: 2,
};


export const listStyles = {
  color: "secondary.main",
  typography: "body2",
  px: 2,
  py: 2,
};


export const helpStyles = {
  color: "secondary.main",
  typography: "body2",
  p: 4,
}