import { 
  ReactElement, 
  useEffect, 
  useState 
} from "react";

import { useNavigate } from "react-router";
import { Link } from 'react-router-dom';
import { NavLink } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Fab from "@mui/material/Fab";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";

import { useApp } from "../AppProvider";
import { useAuth } from "../AuthProvider";
import EventSchedule from "../components/EventSchedule";
import Hourglass from "../components/Hourglass";
import { useData } from "../DataProvider";
import { 
  fabStyles, 
  sectionStyles, 
  tabPanelStyles, 
  tabStyles, 
  titleStyles } from "../styles/styles";

//import NotVerifiedMsg from "../components/NotVerifiedMsg";

interface EventsProps {
  initialTab?: string;
}

export default function Events(props: EventsProps): ReactElement {
  const navigate = useNavigate();

  const { setPageName } = useApp();
  const { isLoggedIn, isLoggingIn } = useAuth();
  const { adminOrgs, userProfile } = useData();
  const [tabSelected, setValue] = useState(props.initialTab ? props.initialTab : "1");

  const handleChange = (event: React.SyntheticEvent, newTabSelected: string) => {
    setValue(newTabSelected);
  };

  useEffect(() => {
    if (!isLoggingIn) {
      if (isLoggedIn) {
        //!isAuthenticated && navigate("/verify", { state: {isEmailSentFirst: false }});
        setPageName("Events");
      } else {
        navigate("/welcome");
      }
    }
  }, [isLoggingIn, isLoggedIn, navigate, setPageName]);

  /* TODO: Rewrite without duplicate code */
  if (userProfile) {
    return (
      <Box sx={tabStyles}>
        <TabContext value={ tabSelected }>
          <Box>
            <TabList 
              onChange={handleChange}
              variant="fullWidth"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab value="1" component={Link} to="/upcoming-events" label="Upcoming" wrapped />
              <Tab value="2" component={Link} to="/past-events" label="Past" wrapped />
            </TabList>
          </Box>
          <TabPanel value="1" sx={tabPanelStyles}>
            <>
              <Box sx={sectionStyles}>
                <Typography sx={titleStyles}>
                    Upcoming Events
                </Typography>
                <EventSchedule isUpcoming={true} />
              </Box> 
              { adminOrgs.length > 0 &&
              <Fab variant="extended" color="primary" aria-label="add event" component={NavLink} to="/event-create" sx={fabStyles}>
                <AddIcon />
                Add event
              </Fab>}
            </>
          </TabPanel>
          {tabSelected === "2" && 
            (<TabPanel value="2" sx={tabPanelStyles}>
            <Box sx={sectionStyles}>
              <Typography sx={titleStyles}>
                Past Events
              </Typography>
              <EventSchedule isUpcoming={false} />
            </Box>
          </TabPanel>)
          }
        </TabContext>
      </Box>
    );
  } else if (isLoggedIn) {
    return (<Hourglass />);
  } else {
    return (<Container />);
  }
}