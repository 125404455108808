import { 
  ReactElement, 
  useState 
} from "react";

import { useNavigate } from "react-router";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography"
import Button from "@mui/material-next/Button";

import { useData } from "../DataProvider";
import { titleStyles } from "../styles/styles";
import { validateEmail } from "../utils/validators";

type Invitation = {
  name: string;
  mailingAddress: string;
  email: string;
  ghin: string; 
  isEmailValid: boolean;
  isEmailInUse: boolean;
  isEmailEdited: boolean
  phone: string;
}

export default function InviteMembers(): ReactElement {
  const navigate = useNavigate();
  const { adminOrgs, addApplication, isOrgAdmin } = useData();

  const [invitation, setInvitation] = 
    useState<Invitation>({name: "", mailingAddress: "", email: "", ghin: "", isEmailValid: false, isEmailInUse: false, isEmailEdited: false, phone: ""});

  const handleInviteMember = () => {
    addApplication(invitation.name, invitation.mailingAddress, invitation.email, invitation.ghin, invitation.phone).then(() => {
      navigate("/applications");
    });
  }

  const handleEmailChange = (val: string) => {
    setInvitation(invitation => ({ 
      ...invitation, 
      email: val,
      isEmailEdited: false,
      isEmailValid: validateEmail(val),
      isEmailInuse: false}));
  }

  if (adminOrgs.length > 0 && isOrgAdmin(adminOrgs[0])) {
    return (
      <Box sx={{ width: '100vw'}}>
        <Grid container alignItems="center" spacing={2} sx={{ width:'100%' }}>
          <Grid item xs={12}>
          <Typography align="left" gutterBottom sx={titleStyles}>
            Invite Members
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5} sx={{py: 4, textDecoration: 'inherit'}}>
          <TextField
            required
            autoFocus={true}
            id={"name"}
            label="Name"                
            aria-label="name"
            fullWidth
            onChange={({ target }) => 
              setInvitation(invitation => ({
                ...invitation, name: target.value
              })
            )}
          />
        </Grid>
        <Grid item xs={12} sm={5} sx={{py: 4, textDecoration: 'inherit'}}>
          <TextField
            required
            error={invitation.isEmailEdited && invitation.isEmailValid === false}                                        
            id={"email"}
            label={invitation.isEmailEdited && invitation.isEmailValid === false ? "Error" : "Email"}
            onBlur={(e) => setInvitation( invitation => ({ ...invitation, isEmailEdited: e.target.value.length > 0 ? true : false }))}  
            aria-label="Email"
            helperText={invitation.isEmailEdited && invitation.isEmailValid === false ? 
                  (invitation.isEmailInUse ? "Email already in use." : "Invalid email address") : ""}
                fullWidth
                value={invitation.email }
                onChange={(e) => handleEmailChange(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={2} sx={{py: 4, textDecoration: 'inherit'}}>
            <Button
              variant="filledTonal"
              color="primary"
              onClick={() => {
                handleInviteMember()
            }}
          >
            Invite
          </Button>
        </Grid>
      </Grid>
      </Box>
    );
  } else {
    // navigate("/");
    return <></>;
  }
};