import { 
  ReactElement, 
  useEffect 
} from "react";

import { 
  collection, 
  orderBy, 
  Query, 
  query, 
  where } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { NavLink } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { useAuth } from "../AuthProvider";
import Hourglass from "../components/Hourglass";
import { useData } from "../DataProvider";
import { firestore } from "../firebase";
import { sectionStyles, titleStyles } from "../styles/styles";
import UserProfile from "../types/UserProfile";

import { PersonRow } from "./PersonRow";

interface PersonDirectoryProps {
  isActive: boolean;
}

export const PersonDirectory = (props: PersonDirectoryProps): ReactElement => {

  const { isRevealingGodsSecrets } = useAuth();
  const { adminOrgs, isOrgAdmin } = useData();
  
  const qMembers = query(
    collection(firestore, "profile" ),
    where("isActive", "==", props.isActive),
    orderBy("name")
  ) as Query<UserProfile>;

  const [members, isLoading, isError] = useCollectionData<UserProfile>(qMembers);

  useEffect (() => {
    
  }, [members]);

  if (isLoading) {
    return <Box width='100vw' />;
  } else if (isError) {
    return <Box width='100vw' />;
  } else if (members) {
    return (
      <Box sx={sectionStyles}>
        <Grid container sx={{ width: "100%" }}>
          <Grid item xs={12}>
            <Typography align="left" gutterBottom sx={titleStyles}>
              {adminOrgs && isOrgAdmin(adminOrgs[0]) ? props.isActive ? "Active Members" : "Guests" : "Member Directory"}
            </Typography>
          </Grid> 
          <Grid item xs={12}>
            { members.flatMap((member: UserProfile) => {
              if (isRevealingGodsSecrets || !member?.isTest) {
                return (
                  <NavLink 
                    color="inherit" 
                    key={member.itemId}
                    style={() => ({ textDecoration: 'none', color: "inherit" })}
                    children={({ isActive }) => (
                      <PersonRow key={member.itemId} person={member} isActive={isActive}/>)
                    } 
                    to={`/profile/${member.itemId}`}
                    state={{navAfterUpdate: "/members", initialTab: "2"}} 
                  />
                );                 
              } else {
                return null;
              }
            })}
          </Grid>   
        </Grid>
      </Box>
    );
  } else {
    return (<Hourglass />);;
  }
}