import { 
  ReactElement, 
  useEffect 
} from "react";

import { Timestamp } from "firebase/firestore";
import { useNavigate } from "react-router";

import Box from "@mui/material/Box";

import { useApp } from "../AppProvider";
import EventEditor from "../components/EventEditor";
import { useData } from "../DataProvider";
import Event from "../types/Event";

export default function EventCreatePage(): ReactElement {
  const { setPageName } = useApp();
  const { adminOrgs, isOrgAdmin } = useData();
  const navigate = useNavigate();

  useEffect (() => {
    setPageName("Create Event");
  }, [setPageName]);

  const createNewEvent = (): Event => {
    const newEvent: Event = { 
      name: '', 
      courseId: '',
      creatorId: '',
      datetime: Timestamp.now(),
      endTime: Timestamp.now(),
      description: '',
      isGolf: true,
      isShotgun: false,
      isTest: false,
      itemId: '',
      leagueId: '',
      orgId: adminOrgs[0],
      leagueName: '',
      logo: '',
      maxPlayers: 0,
      numTeeTimes: 0,
      signupOpensAt: Timestamp.now(),
      signupClosesAt: Timestamp.now(),
      socialLocation: "",
      timezone: "America/Los_Angeles"
    };
    return newEvent;
  };

  if (adminOrgs.length > 0 && isOrgAdmin(adminOrgs[0])) {
    const event = createNewEvent();

    return (
      <Box>
        <EventEditor event={event} isNewEvent={true} />
      </Box>
    );
  } else {
    navigate("/");
    return <></>;
  }
};
// && IsEventSignupOpen(event.signupOpensAt, event.signupClosesAt) && 
// {event.isGolf && IsEventUpcoming(event.datetime) && IsEventSignupClosed(event.signupClosesAt) &&