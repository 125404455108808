import { 
  ReactElement, 
  useEffect, 
} from "react";

import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Fab from "@mui/material/Fab";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { useAuth } from "../AuthProvider";
import EventCard from "../components/EventCard";
import EventSchedule from "../components/EventSchedule";
import Hourglass from "../components/Hourglass";
import LeagueResults from "../components/LeagueResults";
import { useData } from "../DataProvider";
import { 
  dividerStyles, 
  fabStyles, 
  sectionStyles,  
  titleStyles } from "../styles/styles";
import Event from "../types/Event";
import { IsEventSignupOpen } from "../utils/utils";

//import NotVerifiedMsg from "../components/NotVerifiedMsg";

export default function Home(): ReactElement {
  const navigate = useNavigate();

  const { isRevealingGodsSecrets, isLoggedIn, isLoggingIn } = useAuth();
  const { adminOrgs, events, userProfile } = useData();

  useEffect(() => {
    if (!isLoggingIn) {
      if (isLoggedIn) {
        //!isAuthenticated && navigate("/verify", { state: {isEmailSentFirst: false }});
      } else {
        navigate("/welcome");
      }
    }
  }, [isLoggingIn, isLoggedIn, navigate]);

  /* TODO: Rewrite without duplicate code */
  if (userProfile) {
    return (
      <Box sx={sectionStyles}>
        <Box sx={sectionStyles}>
          <Typography sx={titleStyles}>Open for Signup</Typography>
          <Grid container direction="row" spacing={1}>
            {events &&
              events.flatMap((event: Event) => {
                if (IsEventSignupOpen(event.signupOpensAt, event.signupClosesAt) && (!event.isTest || isRevealingGodsSecrets)) {
                  return (
                    <Grid item key={"open" + event.itemId} xs={12} sm={6} md={4} minWidth={300}>
                      <EventCard  {...event} />
                    </Grid>
                  );
                } else {
                  return [];
                }
              })}
          </Grid>
        </Box>
        <Divider variant="middle" sx={dividerStyles} />
        <Box sx={sectionStyles}>
          <Typography sx={titleStyles}>
              Eclectic Results
          </Typography>
          <LeagueResults leagueId="2BVRFRpII6n2kkzKfwoi"orgId="zRQde8GJXIflkGlRSwpU" memberId={userProfile.itemId}/>
          <Button variant="contained" color="primary" component={NavLink} to="/league/2BVRFRpII6n2kkzKfwoi" sx={{ml: 8}}>
            View all
          </Button>
        </Box> 
        <Divider variant="middle" sx={dividerStyles} />
        <Box sx={sectionStyles}>
          <Typography sx={titleStyles}>
              Upcoming Events
          </Typography>
          <EventSchedule isUpcoming={true} />
        </Box> 
        { adminOrgs.length > 0 &&
        <Fab variant="extended" color="primary" aria-label="add event" component={NavLink} to="/event-create" sx={fabStyles}>
          <AddIcon />
          Add event
        </Fab>}
      </Box>
    );
  } else if (isLoggedIn) {
    return (<Hourglass />);
  } else {
    return (<Container />);
  }
}