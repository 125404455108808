import { 
  ReactElement, 
  useEffect,
  useState, 
} from "react";

import { 
  collection, 
  Query, 
  query, 
  where 
} from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";

import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material-next/Button";

import { useData } from "../DataProvider";
import { firestore } from "../firebase";
import { subtitleStyles } from "../styles/styles";
import Person from "../types/Person";
import Registration from "../types/Registration";
import { GetFirstName } from "../utils/utils";

import PersonAutocomplete from "./PersonAutocomplete";


type PartnerState = {
  partnerId: string | undefined;
  partnerName?: string;
  isPartnerMismatch: boolean;
  isBeingInvited: boolean;
  isInviting: boolean;
  isPartnerConfirmed: boolean;
}

interface PartnerSelectorProps {
  registration: Registration;
  isMemberMember?: boolean;
  isMemberGuest?: boolean;
}

export default function PartnerSelector(props: PartnerSelectorProps): ReactElement {
  const registration = props.registration;

  const { updateEventRegistration, userProfile } = useData();
  const [partnerState, setPartnerState] = useState<PartnerState | null>(null);
  const [changeConfirm, setChangeConfirm] = useState(false);

  const qReg = query(
    collection(firestore, "event/" + props.registration.eventId + "/reg" ),
    where("partnerId", "==", props.registration.playerId),
  ) as Query<Registration>;

  const [partnerRegs, isPartnerRegLoading, isPartnerRegError] = useCollectionData<Registration>(qReg);

  useEffect(() => {
    if (registration) {
      console.log("Registration loaded. Setting partners: partnerReg count: ", partnerRegs?.length);
      switch (partnerRegs?.length) { 
        case 0:
          setPartnerState({
            partnerId: registration.partnerId, 
            partnerName: registration.partnerName,
            isPartnerMismatch: false, 
            isBeingInvited: false, 
            isInviting: registration?.partnerId !== "", 
            isPartnerConfirmed: false
          });
          break;
        case 1:
          setPartnerState({
            partnerId: partnerRegs[0].playerId, 
            partnerName: partnerRegs[0].playerName,
            isPartnerMismatch: registration.partnerId !== "" && (registration.partnerId !== partnerRegs[0].playerId), 
            isBeingInvited: !registration.partnerId,
            isInviting: false,
            isPartnerConfirmed: registration.partnerId === partnerRegs[0].playerId
          });
          break;
        default: 
          {
            const possiblePartnerId = partnerRegs?.find((reg) => reg.playerId === registration.partnerId)?.playerId || "";
            setPartnerState ({
              partnerId: possiblePartnerId,
              isPartnerMismatch: false, 
              isBeingInvited: possiblePartnerId !== "" && (possiblePartnerId !== registration.partnerId),
              isInviting: possiblePartnerId !== "" && (possiblePartnerId !== registration.partnerId),
              isPartnerConfirmed: possiblePartnerId === registration.partnerId
            });
            console.log("Multiple partner registrations found. Verify partner is an active reg.");
          }
          break;
      }
    } else {
      console.log("Registration not loaded. Can''t set partners.");
    }
  }, [registration, partnerRegs]);

  useEffect(() => {
    if (isPartnerRegError) {
        console.log("Error loading registration");
    } else if (isPartnerRegLoading) {
        console.log("Loading partner registration");
    }
  }, [isPartnerRegLoading, isPartnerRegError]);

  const handleRegChange = (updatedRegistration: Partial<Registration>) => {
    if (userProfile) {
      try {
        console.log("Updating registration info: " + props.registration.eventId, userProfile.itemId, {...updatedRegistration});
        updateEventRegistration(props.registration.eventId, userProfile.itemId, {...updatedRegistration} );
      } catch {
        console.log("Error updating registration info");
      }
    } else {
      console.log("User not logged in");
    }
  };

  const handlePartnerChange = (event: React.SyntheticEvent, option: Person | null, /* reason: string */) => {
    if (option !== null) {
      console.log("Selected in Attendeelist: " + option?.name)
    } else {
      console.log("Selected in Attendeelist: null")
    }
    if (registration) {
      updateEventRegistration(
        registration.eventId, 
        registration.playerId, 
        {partnerId: option ? option.itemId : "", partnerName: option ? option.name : ""});
      console.log("partner id set: ", option ? option.itemId : "");
    }
  }

  const handleEndPartnership = () => {
    if (userProfile) {
      try {
        updateEventRegistration(props.registration.eventId, userProfile.itemId, {partnerId: "", partnerName: ""} );
        setChangeConfirm(false);
      } catch {
        console.log("Error updating registration info");
      }
    } else {
      console.log("User not logged in");
    }
  }

  if (!registration || isPartnerRegLoading) {
    return (<> </>);
  } else {
    if (props.isMemberMember) {
      if (!partnerState) {
        return (<> </>);
      } else {
        return (
          <>
          {partnerState.partnerId && partnerState.isPartnerConfirmed && 
            <Box>
              <Typography variant="body1">
                Your fabulous partner is  
              </Typography>
              <Typography variant="h6" sx={subtitleStyles}>
                {partnerState.partnerName}
              </Typography>
              <Stack direction="row" spacing={2}>
                {!changeConfirm && <Button 
                  variant="outlined" 
                  color="secondary" 
                  size="small" onClick={() => setChangeConfirm(true)}
                >
                  Change
                </Button>}
                {changeConfirm && (
                    <Stack direction="row" spacing={1}>
                      <Button variant="text" size="small" color="secondary" onClick={() => handleEndPartnership()}>
                        Confirm Ending Partnership
                      </Button>
                      <Button variant="text" size="small" color="secondary" onClick={() => setChangeConfirm(false)}>
                        Don't change
                      </Button>
                    </Stack>
                  )}
              </Stack>
            </Box>
          }
          {!partnerState.isPartnerMismatch && !partnerState.isBeingInvited && !partnerState.isPartnerConfirmed &&
            <Box>
              <PersonAutocomplete 
                id="partner" 
                size="small" 
                emptyLabel="Select Partner"
                label="Partner"
                selectedPersonId={partnerState?.partnerId ? partnerState.partnerId : ""}
                handleOnChange={handlePartnerChange}
              />
              {partnerState.isInviting && !partnerState.isPartnerConfirmed && 
                <Typography variant="caption" color="secondary">
                  {partnerState.partnerName ? GetFirstName(partnerState.partnerName) + " has not yet confirmed." : "Waiting for confirmation."}
                </Typography>
              }
            </Box>

          }
          {partnerState.partnerId && partnerState.isBeingInvited && !partnerState.isPartnerConfirmed &&
            <Box>
              <Typography variant="body1">
                Confirm your partnership with:   
              </Typography>
              <Typography variant="h6" sx={subtitleStyles}>
                {partnerState.partnerName}
              </Typography>
              <Stack direction="row" spacing={2}>
                <Button 
                  variant="filled"
                  onClick={() => handleRegChange({partnerId: partnerState.partnerId, partnerName: partnerState.partnerName})}
                >
                  Confirm
                </Button>
                <Button>Reject</Button>
              </Stack>
            </Box>
          }
          </>
        );
      }
    } else if (props.isMemberGuest) {
      return (
          <TextField
            id="guest name"
            label="Guest"
            aria-label="guest name"
            aria-required="false"
            fullWidth
            defaultValue={registration.partnerName ? registration.partnerName : ""}
            onChange={(e) => {
              if (e != null) {
                handleRegChange({partnerName: e.target.value});
              }
            }}
          />
      );
    } else {
      return (
        <> </>
      );
    }

  }  
}