import React from 'react';

import ReactDOM from 'react-dom/client';
import { 
  BrowserRouter, 
} from "react-router-dom";

import './index.css';
import App from './App';
import { AppProvider } from './AppProvider';
import { AuthProvider } from './AuthProvider';
import { DataProvider } from './DataProvider';
// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <AppProvider>
          <DataProvider>
            <App />
          </DataProvider>
        </AppProvider>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
