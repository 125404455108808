import { 
  ReactElement, 
  useEffect, 
  useState 
} from "react";

import { 
  getDownloadURL, 
  ref 
} from "firebase/storage";
import { Link } from "react-router-dom";



import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

import { useAuth } from "../AuthProvider";
import { imagesRef } from "../firebase";
import { boxStyles } from "../styles/styles";
//import NotVerifiedMsg from "../components/NotVerifiedMsg";

export default function Welcome(): ReactElement {
  const welcomeImageFileName = "welcome.png";
  const [welcomeImageUrl, setWelcomeImageUrl] = useState("");

  const {
    isLoggedIn,
    isLoggingIn,
  } = useAuth();

  useEffect(() => {

  }, [isLoggingIn]);

  useEffect(() => {
    getDownloadURL(ref(imagesRef, welcomeImageFileName)).then((url) => {
        setWelcomeImageUrl(url);
      }).catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Box alignContent="center" sx={{maxWidth: 640}}>
    <Card sx={{ maxWidth: 640 }}> 
      <CardMedia component="img" image={welcomeImageUrl} alt="Welcome" />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Welcome to the SWGA!
        </Typography>
        <Typography variant="body2" color="text.secondary">
          The Sunriver Women's Golf Association (SWGA) is based at the Sunriver Resort in Central Oregon.
        </Typography>
        <Typography variant="body2" color="text.secondary">
          If you live in central Oregon and are interested in golfing or finding out if golf is right for you,
          the SWGA is a great place to start. 
          We have low key events, weekly Wednesday play, team play, senior play, visitations and social events! 
        </Typography>
      </CardContent>
      <CardActions>
        <Button variant="contained" href="/Apply">Apply today</Button>
      </CardActions>
    </Card>
    {!isLoggedIn &&
    <Box sx={{ ...boxStyles}} >
      <Typography variant="body2" color="secondary.text" align="center">
        Already a member? <Link to="/login">Login here</Link>
      </Typography>
    </Box>}
    </Box>

  );
}