import React, { 
  useEffect, 
  useState, 
} from "react";

import { 
  NavLink, 
  useLocation, 
  useNavigate
} from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { Theme } from "@mui/material/styles";
import { SxProps } from '@mui/material/styles';
import SvgIcon from "@mui/material/SvgIcon";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { 
  useApp
} from "../AppProvider";
import { useAuth } from "../AuthProvider";
import { useData } from "../DataProvider";

import MahaloSettingsMenu from "./MahaloSettingsMenu";

interface MahaloAppBarProps {
  sx?: SxProps<Theme>;
}

export default function MahaloAppBar(props: MahaloAppBarProps) {
  const sx = props.sx;
  const location = useLocation();
  const navigate = useNavigate();
  const { actions, getPageTitle, isHomePage, pageContexts } = useApp();
  const { user } = useAuth();
  const { adminOrgs, isOrgAdmin } = useData();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  useEffect(() => {
    const urlSegments = location.pathname.split("/");
    const urlPath = urlSegments[0] + "/" + urlSegments[1];
    console.log("MahaloAppBar location: ", urlPath);
  }, [location]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleTitleClick = () => {
    if (isHomePage) {
      navigate("/");
    } else {
      navigate(-1);
    }
  };

  return (
    <AppBar 
      position="fixed" 
      sx={[
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      <Toolbar sx={{ px: 1 }}>
        {isHomePage && 
        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            {pageContexts.map((page) => (
              ((page.showLogin && user && (!page.showOnlyAdmin || (adminOrgs.length > 0 && isOrgAdmin(adminOrgs[0])))) || 
                (page.showLogout && !user)) ?
                <MenuItem 
                  component={NavLink}
                  key={page.name}
                  to={page.to}
                  onClick={handleCloseNavMenu}>
                 <Typography textAlign="center">{page.name}</Typography>
                </MenuItem> : null
            ))}
          </Menu>
        </Box>}
        { isHomePage &&
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
        }
        <Stack direction="row" alignItems="center">
          <IconButton onClick={handleTitleClick} sx={{mr: 1}}>
            {isHomePage ? <GolfCourseIcon /> : <ArrowBackIcon/>}
          </IconButton>
          <Typography
            variant="h6"
            component={NavLink}
            to="/"
            sx={{
              mr: 2,
              pl: 4,
              display: { xs: "flex" },
              flexGrow: 1,
              fontFamily: isHomePage ? "monospace" : "sans-serif",
              fontWeight: isHomePage ? 700 : "inherit",
              letterSpacing: isHomePage ? ".3rem" : "0rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            {getPageTitle()}
          </Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" alignItems="center">
            {actions.map((action) => (
              <IconButton
                key={action.name}
                onClick={action.handleClick}
                sx={{mr: 1}}
              >
                <SvgIcon component={action.icon} />  
              </IconButton>
            ))}
          </Stack>
        <MahaloSettingsMenu sx={{flexGrow: 0}}/>
      </Toolbar>
    </AppBar>
  );
}