import { 
  ReactElement, 
  useEffect, 
  useRef, 
  useState 
} from "react";

import { 
  collection, 
  orderBy, 
  Query, 
  query, 
  where 
} from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";

import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material-next/Button";

import { useAuth } from "../AuthProvider";
import { useData } from "../DataProvider";
import { firestore } from "../firebase";
import { 
  boxFormStyles, 
  listStyles, 
  sectionStyles, 
  subtitleStyles 
} from "../styles/styles";
import Event from "../types/Event";
import Person from "../types/Person";
import Registration from "../types/Registration";

import Hourglass from "./Hourglass";
import PersonAutocomplete from "./PersonAutocomplete";

type EventAttendeesProps = {
  event: Event;
  isEditable?: boolean;
};

export default function AttendeeList(props: EventAttendeesProps ): ReactElement {
  const event = props.event;
  const isEditable = props.isEditable;

  const { isRevealingGodsSecrets } = useAuth();
  const { addEventRegistration } = useData();
  const personSelected = useRef<Person | null>(null);
  const [addPersonFieldValue, setAddPersonFieldValue] = useState<Person | null>(null);
  const [regList, setRegList] = useState<Registration[]>([]);
  const [waitList, setWaitList] = useState<Registration[]>([]);

  const qReg = query(
    collection(firestore, "event/" + event.itemId + "/reg" ),
    where("isRegistered", "==", true),
    orderBy("datetime")
  ) as Query<Registration>;

  const [regs, isRegLoading, isRegError] = useCollectionData<Registration>(qReg);

  useEffect (() => {

    if (isRegLoading) {
      console.log("Loading registrations");
    } else if (regs) {
      if ((event.maxPlayers !== 0) && (regs.length > event.maxPlayers)) {
        setRegList(regs.slice (0, event.maxPlayers));
        setWaitList(regs.slice (event.maxPlayers, regs.length));
      } else {
        setRegList(regs);
        setWaitList([]);
      }
    } else if (isRegError) {
      console.log("Error loading registrations: " + isRegError);
    }
  }, [regs, isRegLoading, isRegError, event.maxPlayers]);
  
  const handleSelectionChange = (event: React.SyntheticEvent, option: Person | null, /* reason: string */) => {
    if (option !== null) {
      console.log("Selected in Attendeelist: " + option?.name)
    } else {
      console.log("Selected in Attendeelist: null")
    }
    personSelected.current = option;
  }

  const handleAddPerson = () => {
    if (personSelected.current !== null) {
      console.log("Adding person: " + personSelected.current.name);
      addEventRegistration(event.itemId, true, personSelected.current);
      setAddPersonFieldValue(null);
    }
  }

  interface AttendeeRowProps {
    attendee: Registration;
    partner?: Registration | null;
    position?: number;
  }

  // For MemberMember events, assumes that if a partnership has been established, 
  // the attendee has the partner info and the partner may not have registered or confirmed the partnership.
  const AttendeeRow = (props: AttendeeRowProps): ReactElement => {
    const attendee = props.attendee;
    const partner = props.partner;

    const partnerPendingStatus = (event?.isMemberMember && (!partner || !partner.partnerId)) ? " (pending)" : "";
    const playerInfoText = isRevealingGodsSecrets ? " (" + attendee.playerId + ")" : "";
    const partnerInfoText = isRevealingGodsSecrets ? " (" + attendee.partnerId + ")" : "";

      return (
        <Grid item container>
          <Grid item xs={(event?.isMemberMember || event?.isMemberGuest) ? 6 : 12}>
            <Stack alignItems="center" direction="row" gap={2} >
              <Typography sx={listStyles}>
                {(props.position ? props.position + ". " : "") + 
                  attendee.playerName + 
                  playerInfoText}
              </Typography>   
              {!attendee.isPlayingGame && <MoneyOffIcon />}
              <Typography variant="body1" sx={listStyles}>
                {attendee.isEighteen ? "" : " (9)"}
              </Typography>
              {!attendee.isRiding && <DirectionsWalkIcon />}
            </Stack>
          </Grid>
          {(attendee?.partnerName && (event?.isMemberMember || event?.isMemberGuest)) &&
            <Grid item xs={6}>
              <Typography sx={listStyles}>
                {attendee.partnerName + 
                partnerPendingStatus + 
                partnerInfoText}
              </Typography> 
            </Grid>}
        </Grid>
      );
  }

  const AttendeesByPartner = (): [Registration, Registration | null][] => {
    const attendees: [Registration, Registration | null][] = [];
    for (let i = 0; i < regList.length; i++) {
      const attendee = regList[i];
      const partner = regList.find((reg) => (reg.playerId === attendee?.partnerId) || (reg.partnerId === attendee?.playerId)) || null;
      if (attendees.find((a) => (a[0].playerId === attendee?.playerId || a[0].partnerId === attendee?.playerId) || (a[0].playerId === attendee?.partnerId))) {
        continue;
      }
      if (partner && attendee.partnerId !== partner.playerId) {
        attendees.push([partner, attendee]);
      } else {
        attendees.push([attendee, partner]);
      }
    }
    return [...attendees].sort((a, b) => a[0].playerName.localeCompare(b[0].playerName));
  }

  const FormattedEntries = (): ReactElement => {
    if (!isRegLoading && regList) {
      let count = 0;
      return (
        <Box >
          <Box sx={{ ...sectionStyles, flexGrow: 1 }}>
            <Typography color="primary.main" variant="h5" component="h2" sx={subtitleStyles} gutterBottom>
              Attendees
            </Typography>
            <Grid container justifyContent="center" spacing={1}>
              {AttendeesByPartner().map((attendee) => {
                count++;
                return (
                  <AttendeeRow key={count} attendee={attendee[0]} partner={attendee[1]} position={count}/>
                )
              })}
            </Grid>
          </Box>
          {waitList.length > 0 &&
            <Box >
              <Grid container>
                <Grid item xs={12} justifyContent="left">
                  <Typography color="primary.main" variant="h5" component="h2" sx={subtitleStyles} gutterBottom>
                    Wait List
                  </Typography>
                </Grid>
              </Grid>
              <Box sx={{ ...boxFormStyles, flexGrow: 1 }}>
                <Grid container justifyContent="center" spacing={2}>
                  {[...waitList].sort((a, b) => (a.datetime < b.datetime ? -1 : 1)).map((attendee) => {
                    return (
                        <AttendeeRow  key={attendee.playerId} attendee={attendee}/>
                    )
                  })}
                </Grid>
              </Box>
            </Box>}
          {isEditable &&
            <Box sx={listStyles}>
              <Grid container justifyContent="flex-start" spacing={2} alignItems="center">
                <Grid item xs={9}>
                  <PersonAutocomplete 
                    id="addPerson" 
                    size="small" 
                    label="Person to add"
                    selectedPersonId={addPersonFieldValue ? addPersonFieldValue.itemId : ""}
                    handleOnChange={handleSelectionChange}/>
                </Grid>
                <Grid item xs={3}>
                  <Button 
                    type="button" 
                    variant="outlined" 
                    color="secondary" 
                    size="medium" 
                    onClick={() => handleAddPerson()}>
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Box>}
        </Box>
      );
    } else if (isRegLoading) {
      return <Hourglass />;
    } else {
      if (isRegError) {
        console.log("Error loading registrations: " + isRegError);
    }
      return <Box />;
    }
  };

  return <FormattedEntries />;
}
