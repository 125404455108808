import { 
  ReactElement, 
  useEffect,
  useState 
} from "react";

import { 
  collection, 
  orderBy, 
  Query, 
  query
} from "firebase/firestore";
import { useCollectionData } from 'react-firebase-hooks/firestore';

import BlockIcon from '@mui/icons-material/Block';
import DeleteIcon from "@mui/icons-material/Delete";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import UndoIcon from "@mui/icons-material/Undo";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography";
import Button from "@mui/material-next/Button";

import { useAuth } from "../AuthProvider";
import { useData } from "../DataProvider";
import { firestore } from "../firebase";
import { 
  actionStyles, 
  bodyStyles, 
  subtitleStyles, 
  tabPanelInstructionsStyles, 
  titleStyles 
} from "../styles/styles";
import Application from "../types/Application";

import { PersonRow } from "./PersonRow";

export default function Applications(): ReactElement {
  const { isRevealingGodsSecrets } = useAuth();
  const { adminOrgs, deleteApplication, isOrgAdmin, updateApplication } = useData();

  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const qApps = query(
    collection(firestore, "application" ),
    orderBy("name")
  ) as Query<Application>;

  const [apps, isAppsLoading, appsError] = useCollectionData<Application>(qApps);

  const [pendingApps, setPendingApps] = useState<Application[]>([]);
  const [approvedApps, setApprovedApps] = useState<Application[]>([]);
  const [activeApps, setActiveApps] = useState<Application[]>([]);
  const [rejectedApps, setRejectedApps] = useState<Application[]>([]);


  useEffect(() => {
    if (isAppsLoading) {
      console.log("Loading applications");
    }
    if (appsError) {
      console.log("Error loading applications");
    }

    if (apps) {
      setPendingApps(apps.filter(app => !app.isApproved && !app.isRejected));
      setApprovedApps(apps.filter(app => app.isApproved && !app.isRejected && !app.isActive));
      setActiveApps(apps.filter(app => app.isActive));
      setRejectedApps(apps.filter(app => app.isRejected));
    }
  }, [apps, isAppsLoading, appsError]);

  const handleAppUpdate = (app: Application, isApproved: boolean, isRejected: boolean) => {
    setDeleteConfirm(false);
    try {
      updateApplication(app.itemId, isApproved, isRejected);     
    } catch (error) {
      console.log(error);
    }
  };

  const handleAppDelete = (app: Application) => {
    try {
      deleteApplication(app.itemId);     
    } catch (error) {
      console.log(error);
    }
  };

  const PendingMemberSection = (): ReactElement => {
    if (!pendingApps || pendingApps.length === 0) {
      return <Box width='100vw' />;
    } else {
      return (
        <Container>
          <Typography align="left" gutterBottom sx={titleStyles}>
              Inbox
            </Typography>
            <Typography align="left" variant="body1" gutterBottom sx={bodyStyles}>
              These applications are waiting for your approval.
            </Typography>
            { pendingApps.flatMap((app: Application) => {
              if (isRevealingGodsSecrets || !app?.isTest) {
                return (
                  <PersonRow key={app.itemId} person={app} checkEmail={true} actions= 
                    <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} justifyContent="flex-start" alignItems="center" sx={actionStyles}>                 
                      <Button size="small" variant="text" color="secondary" startIcon={<PersonAddIcon />} onClick={() => handleAppUpdate(app, true, false)}>
                        Approve
                      </Button>
                      <Button size="small" variant="text" color="secondary" startIcon={<BlockIcon />} onClick={() => handleAppUpdate(app, false, true)}>
                        Reject
                      </Button>
                    </Stack> 
                  />
                );
              } else {
                return null;
              }
            })}
          </Container>
        );
      }
    }

  const ApprovedMemberSection = (): ReactElement => {
    if (!approvedApps || approvedApps.length === 0) {
      return <Box width='100vw' />;
    } else {
        return (
          <Container>
            <Typography align="left" gutterBottom sx={titleStyles}>
              Approved
            </Typography>
            <Typography align="left" variant="body1" gutterBottom sx={bodyStyles}>
              These applications are waiting for the applicant to complete the sign-up process.
            </Typography>
            { approvedApps.flatMap((app: Application) => {
              if (!app?.isTest || isRevealingGodsSecrets) {
                return (
                  <PersonRow key={app.itemId} person={app} actions={
                    <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} justifyContent="flex-start" alignItems="center" sx={actionStyles}> 
                      <Button size="small" variant="text" color="secondary" startIcon={<UndoIcon />} onClick={() => handleAppUpdate(app, false, false)}>
                        Undo approval
                      </Button>
                    </Stack>
                  }/>
                );
              } else {
                return null;
              }
            })}
          </Container>);
      }
  }

  const ActiveMemberSection = (): ReactElement => {
    if (!activeApps || activeApps.length === 0) {
      return <Box width='100vw' />;
    } else {
        return (
          <Container>
            <Typography align="left" gutterBottom sx={titleStyles}>
              Active
            </Typography>
            <Typography align="left" variant="body1" gutterBottom sx={bodyStyles}>
              These applicants are active members. You can manage them from the Members tab.
            </Typography>
            { activeApps.flatMap((app: Application) => {
              if (!app?.isTest || isRevealingGodsSecrets) {
                return (
                  <PersonRow key={app.itemId} person={app} />
                );
              } else {
                return null;
              }
            })}
          </Container>);
    }
  }

  const RejectedAppSection = (): ReactElement => {
    if (!rejectedApps || rejectedApps.length === 0) {
      return <Box width='100vw' />;
    } else {
        return (
          <Container>
            <Typography align="left" gutterBottom sx={titleStyles}>
              Rejected
            </Typography>
            <Typography align="left" variant="body1" gutterBottom sx={bodyStyles}>
              These applications were rejected. The applicant cannot access the site.
            </Typography>
            { rejectedApps.flatMap((app: Application) => {
              if (!app?.isTest || isRevealingGodsSecrets) {
                return (
                  <PersonRow key={app.itemId} person={app} actions={                    
                    <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} justifyContent="flex-start" alignItems="center" sx={actionStyles}> 
                      {!deleteConfirm &&
                        <Button size="small" variant="text" color="secondary" startIcon={<DeleteIcon />} onClick={() => setDeleteConfirm(true)}>
                          Delete
                        </Button>
                      }
                      {!deleteConfirm &&
                        <Button size="small" variant="text" color="secondary" startIcon={<UndoIcon />} onClick={() => handleAppUpdate(app, app.isApproved, false)}>
                          Undo rejection
                        </Button>
                      }
                      {deleteConfirm &&
                        <Button size="small" variant="filledTonal" color="secondary" startIcon={<DeleteIcon />} onClick={() => handleAppDelete(app)}>
                          Confirm
                        </Button>
                      }
                      {deleteConfirm &&
                        <Button size="small" variant="text" color="secondary" onClick={() => setDeleteConfirm(false)}>
                          Cancel
                        </Button>
                      }
                  </Stack>}
                />
              );
            } else {
              return null;
            }
          })}
        </Container>);
    }
  }

  const PendingMemberInstructions = (): ReactElement => {
    return (
      <>
        <ListItem>
          <ListItemIcon>
            <PersonAddIcon/>
          </ListItemIcon>
          <ListItemText 
            primary="Approve"
            primaryTypographyProps={{ sx: { color: 'secondary.light'}}}
            secondary={
              <>
                <Typography component={'span'} variant="body2">
                  The application is approved. They receive an email explaining how to finish signing up.
                </Typography>
              </>}
              secondaryTypographyProps={{ sx: { color: 'secondary.light'}}}
            />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <BlockIcon />
          </ListItemIcon>
          <ListItemText 
            primary="Reject"
            primaryTypographyProps={{ sx: { color: 'secondary.light'}}}
            secondary={
              <>
                <Typography variant="body2" component={'span'}>
                  The application is rejected.
                </Typography>
              </>} 
            secondaryTypographyProps={{ sx: { color: 'secondary.light'}}}
          />
        </ListItem>
      </>); 
  }

  const ApprovedMemberInstructions = (): ReactElement => {
    return (
      <>
        <ListItem>
          <ListItemIcon>
            <UndoIcon />
          </ListItemIcon>
          <ListItemText 
            primary="Undo approval"
            primaryTypographyProps={{ sx: { color: 'secondary.light'}}}
            secondary={
              <>
                <Typography component={'span'} variant="body2">
                  Approval is suspended. The application has to be re-approved to become a member.
                </Typography>
              </>}
              secondaryTypographyProps={{ sx: { color: 'secondary.light'}}}
            />
        </ListItem>
      </>); 
  }

  const RejectedMemberInstructions = (): ReactElement => {
    return (
      <>
        <ListItem>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText 
            primary="Delete"
            primaryTypographyProps={{ sx: { color: 'secondary.light'}}}
            secondary={
              <>
                <Typography component={'span'} variant="body2">
                  The application is permanently deleted.
                </Typography>
              </>}
              secondaryTypographyProps={{ sx: { color: 'secondary.light'}}}
            />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <UndoIcon />
          </ListItemIcon>
          <ListItemText 
            primary="Undo rejection"
            primaryTypographyProps={{ sx: { color: 'secondary.light'}}}
            secondary={
              <>
                <Typography component={'span'} variant="body2">
                  Rejection is reversed and the application returns to its previous state.
                </Typography>
              </>}
              secondaryTypographyProps={{ sx: { color: 'secondary.light'}}}
            />
        </ListItem>
      </>
    ); 
  }

  if (adminOrgs.length > 0 && isOrgAdmin(adminOrgs[0])) {
    return (
      <>
        <Grid container spacing={1}>                       
          <Grid item xs={12}>
            <PendingMemberSection />
            <ApprovedMemberSection />
            <ActiveMemberSection />
            <RejectedAppSection />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" align="left" gutterBottom  sx={subtitleStyles}>
              Instructions
            </Typography>
            <Typography align="left" variant="body1" gutterBottom sx={bodyStyles}>
              Information about the actions that can be taken on each application.
            </Typography>
          </Grid>
          <Grid item xs={12} sx={tabPanelInstructionsStyles}>
            {(pendingApps && pendingApps.length > 0) ? <PendingMemberInstructions /> : null}
            {(approvedApps && approvedApps.length > 0) ? <ApprovedMemberInstructions /> : null}
            {(rejectedApps && rejectedApps.length > 0) ? <RejectedMemberInstructions /> : null}
          </Grid>
        </Grid>
      </>
    );
  } else {
    return <Container />;
  }
}