import { 
  ReactElement, 
  useState 
} from "react";

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { Constitution } from "../components/Constitution";
import { Tournaments } from "../components/Tournaments";
import { WeeklyPlay } from "../components/WeeklyPlay";
import { boxFormStyles } from "../styles/styles";

export default function Handbook(): ReactElement {
  const [tabSelected, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newTabSelected: number) => {
    setValue(newTabSelected);
  };

  return (
    <Container>
      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Tabs
          value={tabSelected}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {/*<Tab label="Weekly Schedule" wrapped />*/}
          <Tab label="Tournaments" wrapped />
          {/*<Tab label="Team Play"  wrapped />*/}
          {/*<Tab label="COSWGA"  wrapped />*/}
          {/*<Tab label="Visitations"  wrapped />*/}
          {/*<Tab label="Meetings"  wrapped />*/}
          <Tab label="Weekly Competition Guidelines" wrapped />
          {/*<Tab label="Rules" wrapped />*/}
          {/*<Tab label="Posting Rule" wrapped />*/}
          {/*<Tab label="Board"  wrapped />*/}
          {/*<Tab label="Budget" wrapped />*/}
          <Tab label="Constitution and Bylaws" wrapped />
          {/*<Tab label="Past Champions" wrapped />*/}
          {/*<Tab label="Past Presidents" wrapped />*/}
        </Tabs>
      </Box>    
      <Box sx={{ ...boxFormStyles }}>
        { (tabSelected === 0) && <Tournaments />}
        { (tabSelected === 1) && <WeeklyPlay />}
        { (tabSelected === 2) && <Constitution /> }
      </Box>
    </Container>
  );

}