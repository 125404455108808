import { 
  ReactElement, 
  useEffect
} from "react";

import { 
  doc, 
  DocumentReference 
} from "firebase/firestore";
import { useDocumentData } from 'react-firebase-hooks/firestore';

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { useData } from "../DataProvider";
import { firestore } from "../firebase";
import {
  dividerStyles,
  sectionStyles, 
} from "../styles/styles";
import Registration from "../types/Registration";

interface EventRegProps {
  eventId: string;
  isMemberGuest?: boolean;
  isMemberMember?: boolean;
} 

export default function EventRegEditor(props: EventRegProps): ReactElement {
  const { updateEventRegistration, userProfile } = useData();

  const [registration, isRegistrationLoading, isRegistrationLoadingError] = 
    useDocumentData<Registration>(doc(firestore, "event/" + props.eventId + "/reg/" + userProfile?.itemId) as DocumentReference<Registration>);

  // Todo handle loading errors and waiting to load hourglass
  useEffect(() => {
    if (isRegistrationLoadingError) {
      console.log("Error loading registration");
    } else if (isRegistrationLoading) {
      console.log("Loading registration");
    } else {
      if (props.isMemberMember && registration) {
        console.log("registration: " + registration.isRegistered);
      }
    }

  }, [props.isMemberMember, registration, isRegistrationLoadingError]);

  const handleRegChange = (updatedRegistration: Partial<Registration>) => {
    if (userProfile) {
      try {
        updateEventRegistration(props.eventId, userProfile.itemId, {...updatedRegistration, playerId: userProfile.itemId} );
      } catch {
        console.log("Error updating registration info");
      }
    } else {
      console.log("User not logged in");
    }
  };

  const handleHolesChange = (e: React.MouseEvent<HTMLElement>, isEighteen: boolean) => {
    if (e != null) {
      handleRegChange({isEighteen: isEighteen});
    }
  }

  const handleRidingChange = (e: React.MouseEvent<HTMLElement>, isRiding: boolean) => {
    if (e != null) {
      handleRegChange({isRiding: isRiding});
    }
  }

  const handlePlayingChange = (e: React.MouseEvent<HTMLElement>, isPlayingGame: boolean) => {
    if (e != null) {
      handleRegChange({isPlayingGame: isPlayingGame});
    }
  }

  if (registration && registration.isRegistered) {
    return (
      <Box sx={sectionStyles}>
        <Grid container rowSpacing={4} spacing={2}>
          <Grid item container justifyContent="left" alignItems="flex-start" spacing={6}>
            <Grid item xs={12} justifyContent="left" alignItems="center">
              <Divider sx={dividerStyles} />
            </Grid>
            <Grid item xs={12} justifyContent="left">
              <Stack direction="row" spacing={4}>
                <ToggleButtonGroup 
                  id="holes" 
                  color="primary" 
                  orientation="vertical" 
                  value={registration && registration.isEighteen} 
                  onChange={handleHolesChange} 
                  exclusive>
                  <ToggleButton value={true}>18 holes</ToggleButton>
                  <ToggleButton value={false}>9 holes</ToggleButton>
                </ToggleButtonGroup>
                <ToggleButtonGroup id="ride" color="primary" orientation="vertical" value={registration && registration.isRiding} onChange={handleRidingChange} exclusive>
                  <ToggleButton value={true}>Ride</ToggleButton>
                  <ToggleButton value={false}>Walk</ToggleButton>
                </ToggleButtonGroup>
                <ToggleButtonGroup id="game" color="primary" orientation="vertical" value={registration && registration.isPlayingGame} onChange={handlePlayingChange} exclusive>
                  <ToggleButton value={true}>Play Game</ToggleButton>
                  <ToggleButton value={false}>Don't Play</ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            </Grid>
            <Grid item justifyContent="flex-start" alignItems="center" xs={12} sm={12} md={8} lg={6}>
              <TextField
                id="comments"
                label="Comments"
                aria-label="Comments"
                aria-required="false"
                multiline
                fullWidth
                defaultValue={registration && registration.comment ? registration.comment : ""}
                onChange={(e) => {
                  if (e != null) {
                    handleRegChange({comment: e.target.value});
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  } else {
    return <> </>;
  }
}
