import React, { 
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Auth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  // sendEmailVerification,
  signOut,
  User,
} from "firebase/auth";
import {
  doc, 
  setDoc,  
} from 'firebase/firestore';
import {
  httpsCallable,
} from 'firebase/functions';
import {  
  StorageReference,
} from 'firebase/storage'; 
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate } from 'react-router-dom';

import {  
  auth, 
  cloudFunctions,
  firestore, 
  imagesRef, 
} from './firebase';

export interface AuthProviderProps {
  children?: ReactNode
}

export interface AuthContextModel {
  auth: Auth;
  user: User | null | undefined;
  imagesRef: StorageReference;
  isEmailVerified: boolean;
  isLoggedIn: boolean;
  isLoggingIn: boolean;
  isRevealingGodsSecrets: boolean;
  revealGodsSecrets: (enable: boolean) => void;
  isGod: boolean;
  checkEmailAddressExists: (email: string) => Promise<boolean>;
  signIn: (email: string, password: string) => Promise<void>;
  signOutUser: () => Promise<void>; 
  resetPassword: (email: string) => Promise<void>;
  verifyEmailNow: (uid: string) => Promise<void>;
}

export const AuthContext = React.createContext<AuthContextModel>(
  {} as AuthContextModel,
);

export function useAuth(): AuthContextModel {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }: AuthProviderProps): ReactElement => {
  
  // TODO: Handle user auth error
  const [user, isLoggingIn, isLoginError] = useAuthState(auth);
  
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isGod, setIsGod] = useState<boolean>(false);
  const [isRevealingGodsSecrets, setIsRevealingGodsSecrets] = useState<boolean>(false);


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => { 
      if (user) {
        setIsLoggedIn(true); 
        user.getIdTokenResult().then((tokenResult) => {
          if (tokenResult?.claims?.god) {
            setIsGod(true);
            console.log("God is here");
          }
        });
      } else if (isLoginError) {
        setIsLoggedIn(false);
        console.log(isLoginError);
      } else {
        setIsLoggedIn(false); 
      }
    });
    return unsubscribe;
  }, [user, isLoginError]);


  function revealGodsSecrets(enable: boolean) {
    setIsRevealingGodsSecrets(isGod ? enable : false);
  }

  interface ReqCheckEmailInterface {
    email: string;
  }
  
  interface ResCheckEmailInterface {
    emailExists: boolean;
  }

  const checkEmailAddressExists = async (email: string) => {
    const checkAuthEmailExists = httpsCallable<ReqCheckEmailInterface, ResCheckEmailInterface>(cloudFunctions, 'checkAuthEmailExists');

    checkAuthEmailExists({email: email})
      .then((result) => { 
        console.log("email: ", email, " exists ", result.data.emailExists)
        return result.data.emailExists;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });


    //try {
    //  const q = query(userProfilesCol, where("email", "==", email));
    //  const querySnapshot = await getDocs(q);
    //  return querySnapshot.size === 0;
    //} catch (error) {
    //  alert(error);
    //}
    return false;
  }

  const signIn = async (email: string, password: string) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((/* userCredential */) => {
        // ********* setUser(userCredential.user);          
      })
      .catch ((err) => {
      console.error(err); 
    });
  }

  function signOutUser(): Promise<void> {
    return signOut(auth).then(() => {
      console.log("User signed out");
      <Navigate
        replace={true}
        to="/login"
      />
    })
  }
  function resetPassword(email: string): Promise<void> {
    return sendPasswordResetEmail(auth, email)
  }

  function verifyEmailNow(uid: string): Promise<void> {
    const verifyEmailRef = doc(firestore, "verifyEmailNow/" + uid);
    return setDoc(verifyEmailRef, {itemId: uid});
  }

  //const startVerifyingUser = async (user: User | null) => {
  //  try {
  //    if (user) {
  //      setIsAuthenticated(false);
  //      sendEmailVerification(user)
  //      .then(() => {
      // Email verification sent!
      // ...
  //    })};
  //  } catch (error) {
  //    alert(error);
  //  }
  //}

  const values = {
    user,
    auth,
    imagesRef,
    isEmailVerified: user ? user.emailVerified : false,
    isLoggingIn,
    isLoggedIn, 
    isRevealingGodsSecrets,
    revealGodsSecrets,
    isGod,
    checkEmailAddressExists,
    signIn,
    signOutUser,
    resetPassword,
    verifyEmailNow,
  }
  return (
    <AuthContext.Provider 
      value={values}
    >
      {isLoggingIn && <div>Loading...</div>}
      {children}
    </AuthContext.Provider>
  );
}

//export const useUserContext = (): UserContextState => {
//  return useContext(UserStateContext)
//}