import { 
  ReactElement, 
  useEffect, 
  useState 
} from "react";

import { 
  doc, 
} from "firebase/firestore";
import { 
  getDownloadURL, 
  ref 
} from "firebase/storage";
import { useDocumentData } from 'react-firebase-hooks/firestore';

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import { useData } from "../DataProvider";
import { 
  imagesRef 
} from "../firebase";
import { cardStyles } from "../styles/styles";
import League from "../types/League";

export default function LeagueHeader(props: { leagueId: string }): ReactElement {
  const {
    leaguesCol,
  } = useData();

  const [league, isLeagueLoading, isLeagueLoadingError] = useDocumentData<League>(doc(leaguesCol, props.leagueId));

  const [leagueLogo, setLeagueLogo] = useState("");

  useEffect(() => {
    if (league && league.logo) {
      getDownloadURL(ref(imagesRef, league.logo)).then((url) => {
        setLeagueLogo(url);
      }).catch((error) => {
        console.log(error);
      });
    }
  }, [league, league?.logo]);

  const statusText = () => {
    return("");
  }

  const StyledType = (): ReactElement => {
    return (
      <Box sx={cardStyles.underLine}>
        <Typography align="right" sx={cardStyles.tagline}>
          {league?.competitionLevel && league.competitionLevel > 4 ? "Competitive" : "Casual"}
        </Typography>
      </Box>
    );
  }

  if (isLeagueLoading) {
    return <></>;
  } else if (isLeagueLoadingError) {
    return <></>;
  } else if (league) {
    return (
    <>
      <Box>
        <Box sx={cardStyles.overline}>
          { leagueLogo && 
            <Avatar sx={cardStyles.avatar} alt='brand logo' id={"eventLogo"} src={leagueLogo} />}
          <Typography sx={cardStyles.header}>{statusText()}</Typography>
        </Box>
        <Divider sx={cardStyles.divider}/>
        <StyledType />   
      </Box>
      <Box sx={{p: 4}}>
        {league.name.length > 0 &&
        <Typography component="span" sx={cardStyles.subtitle}>
          {league.name}
        </Typography>}
        <Typography sx={cardStyles.description}>
          {league.description}
        </Typography>
      </Box>
    </>);
  } else {
    return (<Box />);
  }
}
