import { useState } from "react";

import PasswordIcon from '@mui/icons-material/Password';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";

import { passwordReset } from "../firebase";
import { boxStyles } from "../styles/styles";

  function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [isEmailSent, setIsEmailSent] = useState(false);

    const handleSubmit = async () => {
      passwordReset(email).then(() => {
        setIsEmailSent(true);
      }).catch((error) => {  
        if (error.code === 'auth/user-not-found') {
          alert('User not found, try again!');
          setEmail('');
        }
      });
    };
    
    return (
      <Container maxWidth="md">
        { isEmailSent ?
          <Box sx={boxStyles}>
            <Typography variant="h6" color="primary.text" align="center">
              An email to reset your password has been sent. Check your inbox!
            </Typography>
          </Box> :
          <Box component="form" sx={{...boxStyles}} 
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}          
          >
            <Stack spacing={2}>
            <TextField
              fullWidth
              required
              id="email"
              label="email"
              aria-label="email"
              placeholder="name@email.com"
              helperText="Email address to reset password"
              onChange={({ target }) => setEmail(target.value)}
            />
            <Button type="submit" variant="contained" startIcon={<PasswordIcon/>}>
              Reset Password
            </Button>
            </Stack>
          </Box>
        }
      </Container>
    )
  }

  export default ForgotPassword