import { 
  ReactElement, 
  useState 
} from 'react';

import { Link } from 'react-router-dom';
import { NavLink } from "react-router-dom";

import AddIcon from '@mui/icons-material/Add';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Tab from '@mui/material/Tab';

import { useAuth } from '../AuthProvider';
import Applications from '../components/Applications';
import { GhinCsvUpdate } from '../components/GhinCsvUpdate';
import { PersonDirectory } from '../components/PersonDirectory';
import { useData } from '../DataProvider';
import { 
  fabStyles, 
  tabPanelStyles, 
  tabStyles 
} from "../styles/styles";

interface MembersProps {
  initialTab?: string;
}

//const getVisibilityStyle = (hiddenCondition: boolean): any => {
//  if (hiddenCondition) {
//      return {
//          visibility: 'hidden',
//          height: 0,
//      };
//  }
//  return {
//      visibility: 'visible',
//      height: 'inherit',
//  };
//};

export default function Members(props: MembersProps): ReactElement {
  const { isGod } = useAuth();
  const { adminOrgs } = useData();

  const [tabSelected, setValue] = useState(props.initialTab ? props.initialTab : "1");

  const handleChange = (event: React.SyntheticEvent, newTabSelected: string) => {
    setValue(newTabSelected);
  };

  if (adminOrgs.length > 0) {
    return (
      <Box sx={tabStyles}>
        <TabContext value={ tabSelected }>
          <Box>
            <TabList 
              onChange={handleChange}
              variant="fullWidth"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab value="1" component={Link} to="/members" label="Members" wrapped />
              <Tab value="2" component={Link} to="/applications" label="Applications" wrapped />
            </TabList>
          </Box>
          <TabPanel value="1" sx={tabPanelStyles}>
            <PersonDirectory isActive={true}/>
            {isGod && <GhinCsvUpdate />}
            <Fab variant="extended" color="primary" aria-label="invite member" component={NavLink} to="/invite-members" sx={fabStyles}>
              <AddIcon />
              Add member
            </Fab>
          </TabPanel>
          <TabPanel value="2" sx={tabPanelStyles}>
            <Applications />
          </TabPanel>
        </TabContext>
      </Box>
    );
  } else {
    return (
      <PersonDirectory isActive={true} />
    );
  }  
}