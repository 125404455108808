import { 
  createContext,
  ReactElement, 
  ReactNode, 
  useContext,
  useEffect,
  useState,
 } from 'react';

 import { 
   arrayUnion,
  collection,
  CollectionReference,
  deleteDoc, 
  doc, 
  DocumentData,
  getDoc,
  getDocs, 
  orderBy,
  query,
  setDoc, 
  Timestamp,
  updateDoc,
  where, 
} from 'firebase/firestore';
import { 
  deleteObject, 
  ref,
  uploadBytes, 
} from 'firebase/storage'; 
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';

import { useAuth } from "./AuthProvider";
import { firestore } from './firebase';
import { CurrentDateTime } from "./utils/utils";

import Application from './types/Application';
import Course from './types/Course';
import Event from './types/Event';
import Inquiry from './types/Inquiry';
import League from './types/League';
import Membership from './types/Membership';
import Org from './types/Org';
import Person from './types/Person';
import Registration from './types/Registration';
import Result from './types/Result';
import Season from './types/Season';
import UserProfile from './types/UserProfile';

export interface DataProviderProps {
  children?: ReactNode
}

// This is just a helper to add the type to the db responses
const createCollection = <T = DocumentData>(collectionName: string) => {
  return collection(firestore, collectionName) as CollectionReference<T>;
};

const applicationsCol = createCollection<Application>("application");
const coursesCol = createCollection<Course>("course");
const eventsCol = createCollection<Event>("event");
const inquiriesCol = createCollection<Inquiry>("inquiry");
// todo: remove hardwired SWGA org id and replace with collectionGroup query
const leaguesCol = createCollection<League>("org/zRQde8GJXIflkGlRSwpU/league");
const seasonsCol = createCollection<Season>("org/zRQde8GJXIflkGlRSwpU/season");
const membershipsCol = createCollection<Membership>("org/zRQde8GJXIflkGlRSwpU/membership");
const orgsCol = createCollection<Org>("org");
const userProfilesCol = createCollection<UserProfile>("profile");

export interface DataContextModel {
  applicationsCol: CollectionReference<Application>;
  inquiriesCol: CollectionReference<Inquiry>;

  // User state functions
  userProfile: UserProfile | undefined; 
  isUserActive: boolean;
  isUserLoading: boolean;
  isAdminDisabled: boolean;
  //isAdmin: () => boolean; 
  isLeagueAdmin: (leagueId: string) => boolean;
  isOrgAdmin: (orgId: string) => boolean;
  adminDisabled: (disable: boolean) => void;
  adminOrgs: string[];
  memberOrgs: string[];
  // Application functions
  addApplication: (name: string, mailingAddress: string, email: string, ghin: string, phone: string) => Promise<void>;
  deleteApplication: (appId: string) => Promise<void>;
  updateApplication: (appId: string, isApproved: boolean, isRejected: boolean) => Promise<void>;
  // Course functions
  coursesCol: CollectionReference<Course>;
  courses: Course[] | undefined;
  addCourse: (course: Course) => Promise<Course | null>;
  deleteCourse: (id: string) => Promise<void>;
  updateCourse: (course: Partial<Course>) => Promise<void>;
  // Event functions
  eventsCol: CollectionReference<Event>;
  events: Event[] | undefined;
  getEvent: (id: string) => Promise<Event | null>; 
  addEvent: (event: Event) => Promise<Event | null>;
  deleteEvent: (id: string) => Promise<void>;
  updateEvent: (id: string, event: Partial<Event>) => Promise<void>;
  // Inquiry functions
  addInquiry: (name: string, email: string, phone: string) => Promise<void>;
  // League functions
  leaguesCol: CollectionReference<League>;
  leagues: League[] | undefined;
  addLeague: (league: League) => Promise<League | null>;
  deleteLeague: (id: string) => Promise<void>;
  updateLeague: (league: Partial<League>) => Promise<void>;
  // Season functions
  seasonsCol: CollectionReference<Season>;
  addSeason: (season: Partial<Season>) => Promise<Season | null>;
  addStandingToSeason: (seasonId: string, standing: Result) => Promise<void>;
  deleteSeason: (id: string) => Promise<void>;
  updateSeason: (season: Partial<Season>) => Promise<void>;

  // Org functions
  orgsCol: CollectionReference<Org>;
  orgs: Org[] | undefined;
  // Membership functions
  membershipsCol: CollectionReference<Membership>;
  updateMembership: (membership: Partial<Membership>) => Promise<void>;
  addLeagueMembership: (leagueId: string, memberId: string) => Promise<void>;
  // getMemberships: (orgId: string) => Promise<Membership[]>;
  // Registration functions
  addEventRegistration: (eventId: string, isRegistering: boolean, personToRegister?: Person) => Promise<Registration | null>;
  updateEventRegistration: (eventId: string, playerId: string, registration: Partial<Registration>) => Promise<void>;
  // User Profile functions
  userProfilesCol: CollectionReference<UserProfile>;
  updateUserProfile: (id: string, profile: Partial<UserProfile>, picFile?: File | null) => Promise<void>;
  updateUserIndex: (ghin: string, index: string) => Promise<void>;
}

export const DataContext = createContext<DataContextModel>(
  {} as DataContextModel,
);

export function useData(): DataContextModel {
  return useContext(DataContext)
}

export const DataProvider = ({ children }: DataProviderProps): ReactElement => {
  const { auth, imagesRef, isLoggedIn, user } = useAuth();

  const qCourses = query(coursesCol, orderBy("name"));
  const qEvents = query(eventsCol, orderBy("datetime", "asc"));
  const qLeagues = query(leaguesCol, orderBy("name"));
  const qOrgs = query(orgsCol, orderBy("name"));
  // const qMemberships = query(membershipsCol, orderBy("name"));
  const qUserOrgs = query(membershipsCol, where("itemId", "==", user ? user.uid : 0)); 

  const [isAdminDisabled, setIsAdminDisabled] = useState<boolean>(false);
  const [userProfile, isUserLoading, userError] = useDocumentData<UserProfile>(isLoggedIn && auth.currentUser ? doc(userProfilesCol, auth.currentUser?.uid) : null);
  const [courses, isCoursesLoading, coursesError] = useCollectionData<Course>(isLoggedIn ? qCourses : null);
  const [events, isEventsLoading, eventsError] = useCollectionData<Event>(isLoggedIn ? qEvents : null);
  const [leagues, isLeaguesLoading, leaguesError] = useCollectionData<League>(isLoggedIn ? qLeagues : null);
  const [orgs, isOrgsLoading, orgsError] = useCollectionData<Org>(isLoggedIn ? qOrgs : null);
  // const [memberships, isMembershipsLoading, membershipsError] = useCollectionData<Membership>(isLoggedIn ? qMemberships : null);
  const [userOrgs, isUserOrgsLoading, userOrgsError] = useCollectionData<Membership>(isLoggedIn ? qUserOrgs : null);

  const [adminOrgs, setIsAdminOrgs] = useState<string[]>([]);
  const [memberOrgs, setMemberOrgs] = useState<string[]>([]);

  useEffect(() => {
    if (userProfile) {
      console.log("User profile loaded: " + userProfile.name);
    } else if (userError) {
      console.log("Error loading user profile: " + userError); 
    } else {
      console.log("User profile unloaded.");
    }
  }, [userProfile, userError]);

  useEffect(() => {
    if (isCoursesLoading) {
      console.log("Loading courses");
    } else if (courses) {
      console.log("Courses loaded: " + courses.length + " courses.");
    }
    if (coursesError) {
      console.log("Error loading courses: " + coursesError);
    }
  }, [courses, isCoursesLoading, coursesError]);

  useEffect(() => {
    if (isEventsLoading) {
      console.log("Loading events");
    } else if (events) {
      console.log("Events loaded");
    }
    if (eventsError) {
      console.log("Error loading events: " + eventsError);
    }
  }, [events, isEventsLoading, eventsError]);

  useEffect(() => {
    if (isLeaguesLoading) {
      console.log("Loading leagues");
    } else if (leagues) {
      console.log("Leagues loaded");
    }

    if (leaguesError) {
      console.log("Error loading leagues: " + leaguesError);
    }
  }, [leagues, isLeaguesLoading, leaguesError]);

  useEffect(() => {
    if (isOrgsLoading) {
      console.log("Loading orgs");
    } else if (orgs) {
      console.log("Orgs loaded");
      console.log(orgs);
    }
    if (orgsError) {
      console.log("Error loading orgs: " + orgsError);
    }
  }, [orgs, isOrgsLoading, orgsError]);

  /*
  useEffect(() => {
    if (isMembershipsLoading) {
      console.log("Loading memberships");
    } else if (memberships) {
      console.log("Memberships loaded");
    }

    if (membershipsError) {
      console.log("Error loading memberships: " + membershipsError);
    }
  }, [memberships, isMembershipsLoading, membershipsError]);
  */

  useEffect(() => {
    if (isUserOrgsLoading) {
      console.log("Loading user orgs");
    } else if (userOrgs && userOrgs.length > 0) {
      const adminOrgs = userOrgs.filter(orgMembership => orgMembership.isActive && orgMembership.isAdmin).map(membership => membership.orgId);
      setIsAdminOrgs(adminOrgs);
      if (adminOrgs.length > 0) {
        console.log("adminOrgs: " + adminOrgs[0]);
      } else {
        console.log("No admin orgs found.");
      }
      const memberOrgs = userOrgs.filter(orgMembership => orgMembership.isActive && orgMembership.isMember).map(membership => membership.orgId);
      setMemberOrgs(memberOrgs);
      console.log(memberOrgs);
    }
    if (userOrgsError) {
      console.log("Error loading user orgs: " + userOrgsError);
    }
  }, [userOrgs, isUserOrgsLoading, userOrgsError]);

  // *************** Admin ****************  
  function isOrgAdmin(orgId: string): boolean {
    if (isLoggedIn && userProfile && !isUserOrgsLoading && userOrgs && !isAdminDisabled) {
      const org = userOrgs.find(userOrg => userOrg.orgId === orgId);
      if (userProfile.level === "god" || (org && org.isActive && org.isAdmin)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false; 
    } 
  }

  // TODO: Check to see if current user is an admin of the league.
  function isLeagueAdmin(leagueId: string): boolean {
    if (isLoggedIn && userProfile && !isUserOrgsLoading && userOrgs && !isAdminDisabled &&
      (adminOrgs.length > 0 || userProfile?.level === 'god')) {
      return true;
    } else {
      return false; 
    } 
  }

  function adminDisabled(disable: boolean) {
    setIsAdminDisabled(disable);
  }

  // *************** Application ***************
  const addApplication = async (name: string, mailingAddress: string, email: string, ghin: string, phone: string) => {
    // Note: An application can be created by anyone!!!! Yikes! 
    // Todo: remove hard coding of orgId.
    try {
      //
      const applicationRef = doc(applicationsCol);
      const application: Application = {
        name: name,
        mailingAddress: mailingAddress,
        email: email,
        gender: "",
        ghin: ghin,
        itemId: applicationRef.id,
        isActive: false,
        isApproved: false,
        isRejected: false,
        isPaid: false, 
        isTest: false,
        orgId: "zRQde8GJXIflkGlRSwpU",
        phone: phone,
        datetime: Timestamp.now(),
      };
      await setDoc(applicationRef, application);
    } catch (error) {
      alert(error);
    }
  }

  const deleteApplication = async (appId: string) => {
    if (appId) {
      try {
        //}
        const applicationRef = doc(applicationsCol, appId);
        await deleteDoc(applicationRef);
      } catch (error) {
        alert(error);
      }
    }
    else {
      // TODO: Handle error when user isn't available.
    }
  };

  const updateApplication = async (appId: string, isApproved: boolean, isRejected: boolean) => {
    if (appId) {
      try {
        // reference to the document to update
        const applicationRef = doc(applicationsCol, appId);
    
        // Update the value of the application
        await setDoc(applicationRef, {isApproved: isApproved, isRejected: isRejected}, {merge: true});
      } catch (error) {
        alert(error);
      }
    }
    else {
      // TODO: Handle error when user isn't available.
    }
  };

    // *************** Course functions ****************
  const addCourse = async (course: Course) => {
    try {
      // document reference to be added
      const courseRef = doc(coursesCol);
      
      if (auth.currentUser && course) {
        course.creatorId = auth.currentUser.uid;
        course.createTime = CurrentDateTime();
        course.itemId = courseRef.id;
        await setDoc(courseRef, course).then(() => {
          return course;
        });
      } else {
        // todo: auth.currentUser not defined.
      }
    } catch (error) {
      alert(error);
    }
    return null; 
  };
  
  const deleteCourse = async (id: string) => {
    try {
      // reference to the document to delete
      const courseRef = doc(coursesCol, id);
      await deleteDoc(courseRef);

    } catch (error) {
      alert(error);
    }
  };
  
  const updateCourse = async (course: Partial<Course> ) => {
    try {
      // reference to the document to update
      const courseRef = doc(coursesCol, course.itemId);
  
      // Update the value of the event item
      setDoc(courseRef, 
        {...course}, {merge: true});

    } catch (error) {
      alert(error);
    }
  };

  // *************** Event Items ****************
  const getEvent = async (id: string) => {
    try {
      const eventRef = doc(eventsCol, id);
      const eventDoc = await getDoc(eventRef);
      if (eventDoc.exists()) {
        return eventDoc.data();
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    } catch (error) {
      alert(error);
    }
    return null;
  };

  const addEvent = async (event: Event) => {
    try {
      // document reference to be added
      const eventRef = doc(eventsCol);
      const orgId = leagues?.find(league => league.itemId === event.leagueId)?.orgId;
      
      if (auth.currentUser && event && event.leagueId && orgId) {
        event.orgId = orgId;
        event.creatorId = auth.currentUser.uid;
        event.itemId = eventRef.id;
        await setDoc(eventRef, event).then(() => {
          return event;
        });
      } else {
        alert("Error adding event." + event.name + " " + event.leagueId + " " + orgId);
        // todo: auth.currentUser not defined.
      }
    } catch (error) {
      alert(error);
    }
    return null; 
  };
  
  const deleteEvent = async (id: string) => {
    try {
      // reference to the document to delete
      const eventRef = doc(eventsCol, id);
      await deleteDoc(eventRef);

    } catch (error) {
      alert(error);
    }
  };
  
  const updateEvent = async (id: string, event: Partial<Event>) => {
    try {
      // reference to the document to update
      const eventRef = doc(eventsCol, id);
  
      // Update the value of the event item
      updateDoc(eventRef, {...event});

    } catch (error) {
      alert(error);
    }
  };

  // *************** Inquiry Items ****************
  const addInquiry = async (name: string, email: string, phone: string) => {
    try {
      // document reference to be added
      const inquiryRef = doc(inquiriesCol);

      const inquiry: Inquiry = {
        datetime: Timestamp.now(),
        name: name,
        email: email,
        phone: phone,
        status: "new"
      }

      await setDoc(inquiryRef, inquiry).then(() => {
          return;
        });

    } catch (error) {
      alert(error);
    }
    return; 
  }

  // *************** League functions ****************
  const addLeague = async (league: League) => {
    try {
      const leagueRef = doc(leaguesCol);
          
      if (auth.currentUser && league && leaguesCol) {
        league.creatorId = auth.currentUser.uid;
        league.createTime = CurrentDateTime();
        league.itemId = leagueRef.id;
        // todo: store orgName
        league.orgId = leaguesCol.parent ? leaguesCol.parent.id : "";
        await setDoc(leagueRef, league).then(() => {
          return league;
        });
      } else {
        // todo: auth.currentUser not defined.
      }
    } catch (error) {
      alert(error);
    }
    return null; 
  };
      
  const deleteLeague = async (id: string) => {
    try {
      // reference to the document to delete
      const leagueRef = doc(leaguesCol, id);
      await deleteDoc(leagueRef);

    } catch (error) {
      alert(error);
    }
  };
  
  const updateLeague = async (league: Partial<League> ) => {
    try {
      // reference to the document to update
      const leagueRef = doc(leaguesCol, league.itemId);
  
      // Update the value of the event item
      setDoc(leagueRef, 
        {...league}, {merge: true});

    } catch (error) {
      alert(error);
    }
  };

  // *************** Season functions ****************
  // TODO: Should we use Partial for other Adds? Since creatorId, createTime and itemId are all added at this layer...
  const addSeason = async (season: Partial<Season>) => {
    try {
      const seasonRef = doc(seasonsCol);
            
      if (auth.currentUser && season && seasonsCol) {
        season.creatorId = auth.currentUser.uid;
        season.createTime = CurrentDateTime();
        season.itemId = seasonRef.id;
        // todo: store orgName
        season.orgId = seasonsCol.parent ? seasonsCol.parent.id : "";
        await setDoc(seasonRef, season).then(() => {
          return season;
        });
      } else {
        // todo: auth.currentUser not defined.
      }
    } catch (error) {
      alert(error);
    }
    return null; 
  };

  const addStandingToSeason = async (seasonId: string, standing: Result) => {
    try {
      // reference to the document to update
      const seasonRef = doc(seasonsCol, seasonId);
      const seasonDoc = await getDoc(seasonRef);
      if (seasonDoc.exists()) {
        const season = seasonDoc.data() as Season;
        if (!season.standings) {
          season.standings = [];
        }
        season.standings.push(standing);
        await setDoc(seasonRef, 
          {...season}, {merge: true});
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      alert(error);
    }
  }
        
  const deleteSeason = async (id: string) => {
    try {
      // reference to the document to delete
      const seasonRef = doc(seasonsCol, id);
      await deleteDoc(seasonRef);

    } catch (error) {
      alert(error);
    }
  };
    
  const updateSeason = async (season: Partial<Season> ) => {
    try {
      // reference to the document to update
      const seasonRef = doc(seasonsCol, season.itemId);
    
      // Update the value of the event item
      setDoc(seasonRef, 
        {...season}, {merge: true});
  
    } catch (error) {
      alert(error);
    }
  };

  // *************** Membership functions ****************
  const updateMembership = async (membership: Partial<Membership>) => {
    try {
      // reference to the document to update
      const membershipRef = doc(membershipsCol, membership.itemId);
  
      // Update the value of the event item
      setDoc(membershipRef, 
        {...membership}, {merge: true});

    } catch (error) {
      alert(error);
    }
  }

  const addLeagueMembership = async (leagueId: string, memberId: string) => {
    try {
      // reference to the document to update
      const membershipRef = doc(membershipsCol, memberId);

      const updateLeagues = {
        leagues: arrayUnion(leagueId)
      };

      await updateDoc(membershipRef, updateLeagues);
    } catch (error) {
      alert(error);
    }
  }

  // *************** Event Registration ****************
  const createNewRegistration = (eventId: string, userId: string, userName: string): Registration => {

    const newRegistration: Registration = { 
      eventId: eventId,
      playerId: userId,
      comment: '',
      datetime: CurrentDateTime(),
      group: 0,
      playerName: userName,
      isEighteen: true,
      isPlayingGame: true,
      isRiding: true,
      isRegistered: false,
      isWaitListed: false,
    };
    return newRegistration;
  };

  const addEventRegistration = async(eventId: string, isRegistering: boolean, personToRegister?: Person) => {
    if (auth.currentUser?.uid) {
      try { 
        let reg: Registration;
        let regUserId: string;
        let regUserName: string;
        const event = events?.find(event => event.itemId === eventId);

        if (event && personToRegister && (isOrgAdmin(event.orgId) || personToRegister.itemId === auth.currentUser.uid)) {
          regUserId = personToRegister.itemId;
          regUserName = personToRegister.name;
        } else if (userProfile) {
          regUserId = auth.currentUser.uid;
          regUserName = userProfile.name;
        } else {
          console.log("User not authorized to register for another user.");
          return null;
        }

        const regRef = doc(firestore, "event/" + eventId + "/reg/" + regUserId);
        const regDoc = await getDoc(regRef);
        if (regDoc.exists()) {
          reg = regDoc.data() as Registration;
          reg = {...reg, datetime: CurrentDateTime()};
        } else {
          reg = createNewRegistration(eventId, regUserId, regUserName);
        }
        reg.isRegistered = isRegistering;

        // Update the value of the event item
        setDoc(regRef, 
          {...reg }, {merge: true});
          return reg;
      } catch (error) {
        alert(error);
      }
    }
    return null;
  };

  // TODO: This can be merged with registerForEvent
  const updateEventRegistration = async(eventId: string, playerId: string, registration: Partial<Registration>) => {
    const event = events?.find(event => event.itemId === eventId);

    // A player can update their own registration or an admin can update any registration.
    if (auth.currentUser?.uid && event && ((auth.currentUser.uid === playerId) || isOrgAdmin(event.orgId))) {
      try {
        console.log("wrigint reg: " + eventId + " " + playerId + " " + registration.isRegistered);
        const regRef = doc(firestore, "event/" + eventId + "/reg/" + playerId);

        await setDoc(regRef, 
          {...registration, playerId: playerId }, {merge: true});
      } catch (error) {
        console.log(error);
        alert(error);
      }
    }
  }
  /*
  const getEventRegistrationsByTime = async(eventId: string) => {
    const eventRegistrations: Registration[] = [];

    try {
      // TODO: Order by time
      const q = query(
        collection(firestore, "event/" + eventId + "/reg"),
        where("isRegistered", "==", true),
        orderBy("group")
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        eventRegistrations.push({
          ...doc.data() as Registration
        });
      });
      return eventRegistrations;
    } catch (error) {
      alert(error);
    }
  };
*/
  // *************** UserProfile ****************
  const updateProfileDoc = async (id: string, profile: Partial<UserProfile>) => {
    // reference to the document to update
    const profileRef = doc(firestore, "profile", id);

    // Update the value of the profile
    setDoc(profileRef, {...profile}, {merge: true})
    .then (() => {
      console.log("Profile updated: " + profile.name);
    }).catch((error) => {
      alert(error);
    });
  }

  const updateUserProfile = async (id: string, profile: Partial<UserProfile>, picFile?: File | null ) => {
    if (user) {
      // The profile picture has been changed and needs to be uploaded to storage.
      if (picFile) {
        console.log(profile.picture);
        const pictureRef = ref(imagesRef, profile.picture);
        console.log(pictureRef);
        uploadBytes(pictureRef, picFile).then(() => {
          console.log("Picture uploaded.");
          updateProfileDoc(id, profile).then(() => {
            console.log("Profile updated: " + profile.name);
          }).catch((error) => {
            console.log(error);
          });
        })
        .catch((error) => {
          console.log(error);
        });
      // The profile picture has been deleted and needs to be removed from storage.
      } else if (picFile === null) {
        // Delete the picture
        const pictureRef = ref(imagesRef, profile.itemId);
        // Delete the file
        deleteObject(pictureRef).then(() => {
          console.log("Picture deleted.");
          updateProfileDoc(id, {...profile, picture: "", pictureName: ""}).then(() => {
            console.log("Profile updated: " + profile.name);
          }).catch((error) => {
            console.log(error);
          });
        }).catch((error) => {
          console.log(error);
        });
      // No changes to the profile picture, just update the profile.
      } else {
        updateProfileDoc(id, profile).then(() => {
          console.log("Profile updated: " + profile.name);
        }).catch((error) => {
          console.log(error);
        });
      }
    };
  };

  const updateUserIndex = async (ghin: string, index: string) => {
    try {
      const q = query(userProfilesCol, where("ghin", "==", ghin));
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.docs.length > 0) {
        const doc = querySnapshot.docs[0];
        updateUserProfile(doc.id, {index: index});
      }
    } catch (err) {
      console.log(err); 
    }
  }

  const values = {
    orgsCol,
    orgs,
    userProfile,
    isUserActive: userProfile !== null ? true : false,
    isUserLoading,
    isAdminDisabled,
    //isAdmin, 
    isLeagueAdmin,
    isOrgAdmin,
    adminDisabled,
    adminOrgs,
    memberOrgs,
    applicationsCol,
    addApplication,
    deleteApplication,
    updateApplication,
    coursesCol,
    courses,
    addCourse,
    deleteCourse,
    updateCourse,
    eventsCol,
    events,
    getEvent,
    addEvent,
    deleteEvent,
    updateEvent,
    inquiriesCol,
    addInquiry,
    leaguesCol,
    leagues,
    seasonsCol,
    addSeason,
    addStandingToSeason,
    deleteSeason,
    updateSeason,
    membershipsCol,
    updateMembership,
    addLeagueMembership,
    addLeague,
    deleteLeague,
    updateLeague,
    addEventRegistration,
    updateEventRegistration,
    userProfilesCol,
    updateUserProfile,
    updateUserIndex,
  }

  return (
    <DataContext.Provider
      value={values}>
      {children}
    </DataContext.Provider>
  );
}