import { 
  ReactElement, 
} from "react";

interface LeagueActionBarProps {
  leagueId: string;
}

export default function LeagueActionBar(props: LeagueActionBarProps): ReactElement {

  console.log("LeagueActionBar: " + props.leagueId)
    return (<> </>);
}